<div class="globalBloc">

<h1>MENTIONS LÉGALES</h1>

<h2>Directeur de publication</h2>
<p>Alain Schmitt</p>
<h2>Responsable Web</h2>
<p>Gaëlle Guigon</p>
<h2>Editeur</h2>
<p>Pôle Ingénierie et Ressources Pédagogiques d'IMT Nord Europe<br>Email : <a href="mailto:creationseg@im-nord-europe.fr">creationseg&#64;imt-nord-europe.fr</a></p>
<h2>Hébergeur</h2>
<p>Ce site est hébergé par IMT Nord Europe</p>
<h2>Dysfonctionnement et virus</h2>
<p>IMT Nord Europe ne peut être tenue pour responsable de dommages directs ou indirects, pertes ou frais, résultant de l'utilisation de ce site web, ou de l'impossibilité pour un tiers de l'utiliser, ou d'un mauvais fonctionnement, d'une interruption, d'un virus, ou encore d'un problème de ligne ou de système.</p>
<h2>Crédits</h2>
<p>Les textes de ce site sont sous licence CC-BY-NC-SA (Creative Commons avec autorisation d’utilisation sous condition d’attribution de paternité, d’usage non commercial, et de conditions de partage identiques aux initiales). Icônes réalisées par SimpleIcon, provenant de : https://material.io/resources/icons/?style=baseline et sont sous la licence CC BY 3.0</p>

<!--
<h2>ÉDITEUR</h2>

<p>Ce site a été réalisé et édité par Gaëlle Guigon, Amélia Bouchard et Patrick Delaporte (Pôle Ingénierie et Ressources Pédagogiques) - IMT Lille-Douai</p>

 <mat-icon>email</mat-icon>
  Email : <a routerLink="/contact" target="_blank">Nous contacter</a>

<h2>HÉBERGEUR</h2>

  Ce site est hébergé par l'IMT Lille-Douai.

<h2>CRÉDITS</h2>

  Les textes de ce site sont sous licence CC-BY-NC-SA (Creative Commons avec autorisation d’utilisation sous condition d’attribution de paternité, d’usage non commercial, et de conditions de partage identiques aux initiales).

  Icônes réalisées par SimpleIcon, provenant de : https://material.io/resources/icons/?style=baseline et sont sous la licence CC BY 3.0


<h2>COOKIES</h2>

<h3>Qu'est-ce qu'un cookie ?</h3>

  Un cookie est un fichier texte susceptible d'être enregistré temporairement, sous réserve de vos choix, sur le disque dur de votre terminal à l'occasion de la consultation d'un service en ligne grâce à votre logiciel de navigation.

<h3>Quels cookies utilisons-nous ?</h3>

<strong>Les Cookies analytiques</strong> : Ce sont des cookies qui permettent d'améliorer le fonctionnement de notre site, et surveiller ses performances. Ils permettent notamment l'élaboration de statistiques des audiences de notre site. Ces cookies peuvent être installés et gérés par des tiers (tels que Mediamétrie eStat ou Google Analytics) mais leur utilisation est limitée aux fins de l'analyse statistique demandée. Ces cookies permettent d'analyser les comportements des internautes et optimiser les parties du site internet qui peuvent nécessiter des interventions de maintenance. Les informations recueillies par ces cookies sont anonymes et sont utilisées uniquement à des fins statistiques.

  Ces cookies nécessitent votre autorisation. C'est la raison pour laquelle en vous rendant sur notre site internet, un bandeau est apparu vous informant des finalités précises des cookies, de la possibilité de vous opposer à ces cookies et de changer les paramètres en cliquant sur le lien présent dans le bandeau et du fait que la poursuite de la navigation vaut accord au dépôt de cookies sur votre terminal.

  Nos cookies sont installés pour une durée d'un an. Ils restent dans l'appareil jusqu'à leur expiration ou jusqu'à ce qu'ils soient supprimés du cache de l'ordinateur.

<h3>Désactiver les Cookies</h3>

<ul> <h4>Internet Explorer</h4>
  <li>  Dans le menu Outils, sélectionnez « Options Internet ».</li>
  <li>  Cliquez sur l'onglet Confidentialité.</li>
  <li>  Des paramètres de confidentialité avec six options apparaîtront, qui permettront de contrôler le nombre de cookies qui seront enregistrés : Bloquer Tous Les Cookies, Haute, Moyennement Haute, Moyenne (niveau par défaut), Faible, et Accepter Tous Les Cookies.</li>
</ul>

<ul><h4>Firefox</h4>
  <li>Dans le menu Outils, sélectionnez « options ».</li>
  <li>Sélectionnez l'onglet Vie Privée dans la fenêtre Options.</li>
  <li>Dans le menu déroulant, choisissez « Utiliser les paramètres personnalisés pour l'historique ».</li>
  <li>Les options pour les Cookies seront disponibles. Vous pourrez choisir de les autoriser ou les refuser.</li>
</ul>

<ul><h4>Safari</h4>
  <li>Dans le menu déroulant, sélectionnez l'option « préférences ».</li>
  <li>Ouvrez l'onglet Sécurité.</li>
  <li>Sélectionnez l'option désirée dans la section « accepter les cookies ».</li>
</ul>

<ul><h4>Google Chrome</h4>
  <li>  Dans le menu Paramètres, sélectionnez « Afficher les paramètres avancés ».</li>
  <li>Cliquez sur « Paramètres de contenu » dans la section Confidentialité.</li>
  <li>Une rubrique « Cookies » vous permettra de choisir vos préférences, de gérer les exceptions et de voir les Cookies présents lors de vos visites sur différents sites internet.</li>
</ul>

<ul><h4>Autre navigateurs</h4>
</ul>

  Pour plus d'information sur comment gérer les cookies par l'intermédiaire d'autres navigateurs, veuillez consulter votre documentation ou des fichiers d'aide en ligne.

<h3>Supprimer les Cookies</h3>

<ul><h4>Internet Explorer</h4>
  <li>  Cliquez sur le bouton Outils, puis sur « Options Internet ».</li>
  <li>  Dans l'onglet « Général », dans la rubrique « Historique de navigation », cliquez sur « Paramètres ».</li>
  <li>  Cliquez sur le bouton « Afficher les fichiers ».</li>
  <li>  Trier les fichiers en cliquant l'en-tête de colonne « Nom » pour trier tous les fichiers dans l'ordre alphabétique.</li>
</ul>
  Pour plus d'information sur la gestion des cookies dans « Internet Explorer », cliquez sur le lien suivant :
  <a href="http://windows.microsoft.com/fr-fr/windows7/how-to-manage-cookies-in-internet-explorer-9">http://windows.microsoft.com/fr-fr/windows7/how-to-manage-cookies-in-internet-explorer-9</a>


<ul><h4>Firefox</h4>
  <li>Allez dans l'onglet « Outils » du navigateur puis sélectionnez le menu « Options »</li>
  <li>Dans la nouvelle fenêtre, choisissez l'option « Vie privée » et cliquez sur « Supprimer des cookies spécifiques »</li>
</ul>
  Pour plus d'information sur la gestion des cookies dans « Firefox », cliquez sur le lien suivant :
  <a href="https://support.mozilla.org/fr/kb/activer-desactiver-cookies">https://support.mozilla.org/fr/kb/activer-desactiver-cookies</a>

<ul>
  <h4>Safari</h4>
  <li>Choisissez le menu « édition » puis « Préférences ».</li>
  <li>Choisissez l'option « Sécurité ».</li>
  <li>Cliquez sur le bouton « Afficher les cookies ».</li>
</ul>
  Pour plus d'information sur la gestion des cookies dans « Safari », cliquez sur le lien suivant :
  <a href="http://support.apple.com/kb/ht1677?viewlocale=fr_FR&locale=fr_FR">http://support.apple.com/kb/ht1677?viewlocale=fr_FR&locale=fr_FR</a>

<ul>
  <h4>Google Chrome</h4>
  <li>Cliquez sur le menu Chrome dans la barre d'outils du navigateur et sélectionnez « Paramètres ».</li>
  <li>Cliquez sur « Afficher les paramètres avancés ».</li>
  <li>Dans la section « Confidentialité », cliquez sur le bouton « Paramètres de contenu ».</li>
  <li>Dans la section « Cookies » : cliquez sur le bouton « Cookies et données de site ».</li>
</ul>
  Pour plus d'information sur la gestion des cookies dans « Chrome », cliquez sur le lien suivant :
  <a href="https://support.google.com/chrome/answer/95647">https://support.google.com/chrome/answer/95647</a>

<ul><h4>Autres navigateurs</h4>
</ul>
  Pour plus d'information sur comment gérer les cookies par l'intermédiaire d'autres navigateurs, veuillez consulter votre documentation ou des fichiers d'aide en ligne.

<h3>Des questions ?</h3>
  Pour toute information complémentaire sur les Cookies, nous vous invitons à vous rendre sur les sites :
<a href="http://www.allaboutcookies.org/fr/faqs/cookies.html">http://www.allaboutcookies.org/fr/faqs/cookies.html</a> et <a href="http://www.cnil.fr/vos-droits/vos-traces/les-cookies/">http://www.cnil.fr/vos-droits/vos-traces/les-cookies/</a>
-->


<p><a routerLink="">Retour au site</a></p>
</div>
