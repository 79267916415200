import { Component, OnInit } from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SendSnackbarComponent} from '../send-snackbar/send-snackbar.component';

@Component({
  selector: 'app-contact-section',
  templateUrl: './contact-section.component.html',
  styleUrls: ['./contact-section.component.css']
})
export class ContactSectionComponent implements OnInit {
  durationInSecond = 5;


  constructor(private _snackBar: MatSnackBar) { }

  openSnackBarSend() {
    this._snackBar.openFromComponent(SendSnackbarComponent, {
      duration: this.durationInSecond * 1000,
    });
  }

  ngOnInit() {
  }

}
