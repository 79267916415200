import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-language-picker-modal',
  templateUrl: './language-picker-modal.component.html',
  styleUrls: ['./language-picker-modal.component.css']
})
export class LanguagePickerModalComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<string>) {
  }

  ngOnInit() {
  }

  selectLanguage(language: string) {
    this.dialogRef.close(language);
  }

}
