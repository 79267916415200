import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../auth.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {DiscoSnackbarComponent} from '../../disco-snackbar/disco-snackbar.component';

@Component({
  selector: 'app-disconnect-modal',
  templateUrl: './disconnect-modal.component.html',
  styleUrls: ['./disconnect-modal.component.css']
})
export class DisconnectModalComponent implements OnInit {
durationInSecond = 5;

  constructor(public authService: AuthService, private _snackBar: MatSnackBar) { }

  openSnackBarDisco() {
      this._snackBar.openFromComponent(DiscoSnackbarComponent, {
        duration: this.durationInSecond * 1000,
      });
    }

  ngOnInit(): void {
  }
  }
