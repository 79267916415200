<mat-expansion-panel>
  <mat-expansion-panel-header>
    <mat-panel-title>
      <span translate>sidenav.conclusion</span>
    </mat-panel-title>
  </mat-expansion-panel-header>

  <mat-card class="subTiles">
    <mat-card-header>
      <mat-card-title><span translate>conclusion.financial.label</span></mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <!--
      <mat-form-field>
        <label>
          <input matInput type="number" [(ngModel)]="constraint.budget" placeholder="{{'constraint.budget' | translate}}">
        </label>
      </mat-form-field>
      -->
      <span>Budget: </span>
      <span>{{constraint.budget}}</span>
      <span translate>conclusion.financial.budget</span><br>
      <span translate>conclusion.financial.expenses</span><span> : {{ totalCost }}€</span>
    </mat-card-content>
  </mat-card>

  <!--
  <mat-card class="subTiles">
    <mat-card-header>
      <mat-card-title>
        <span translate>conclusion.educational</span>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      Objectif X utilisé dans l'énigme X <br>
      Compétence X vue dans l'énigme X
    </mat-card-content>
  </mat-card>
  -->
</mat-expansion-panel>
