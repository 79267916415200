import { Skill } from './../../../Models/Skill';
import {Component, OnInit, Input} from '@angular/core';
import {Riddle} from '../../../Models/Riddle';
import {RiddlePreRequisite} from '../../../Models/RiddlePreRequisite';
import {Clue} from '../../../Models/Clue';
import {TranslateService} from '@ngx-translate/core';
import { RiddleSkill } from 'src/app/Models/RiddleSkill';

@Component({
  selector: 'app-structure-subsection',
  templateUrl: './structure-subsection.component.html',
  styleUrls: ['./structure-subsection.component.css']
})
export class StructureSubsectionComponent implements OnInit {

  @Input() riddle: Riddle;
  @Input() stepCount: number;
  @Input() goalSkills: Skill[];

  constructor(private translate: TranslateService) {
  }

  ngOnInit() {
    /*
    this.riddle.answersTo = [];
    this.riddle.clues = [];
    */
  }

  addClue() {
    const newClue = new Clue();
    newClue.label = this.translate.instant('structure.clue.label');
    this.riddle.clues.push(newClue);
  }

  removeClue(index) {
    this.riddle.clues.splice(index, 1);
  }

  addPreRequisite() {
    this.riddle.preRequisite.push(new RiddlePreRequisite());
  }

  removePreRequisite(preRequisite: RiddlePreRequisite) {
    const index = this.riddle.preRequisite.indexOf(preRequisite);

    if (index >= 0) {
      this.riddle.preRequisite.splice(index, 1);
    }
  }

  addAnswerToGoal() {
    this.riddle.answersTo.push(new RiddleSkill());
  }

  removeAnswerToGoal(index) {
    this.riddle.answersTo.splice(index, 1);
    console.log(index);
  }

  get steps(): number[] {
    const steps = [];

    for(let i = 1; i <= this.stepCount; i++) {
      steps.push(i);
    }

    return steps;
  }

}
