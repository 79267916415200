import {Component, Input, OnInit} from '@angular/core';
import {Clue} from '../../../Models/Clue';

@Component({
  selector: 'app-clue-card',
  templateUrl: './clue-card.component.html',
  styleUrls: ['./clue-card.component.css']
})
export class ClueCardComponent implements OnInit {

  @Input() clue: Clue;
  @Input() isTrueClue: boolean;
  @Input() stepCount: number;

  constructor() {
  }

  ngOnInit() {}

  get steps(): number[] {
    let steps = [];

    for(let i = 1; i <= this.stepCount ? this.stepCount : 0; i++) {
      steps.push(i);
    }

    return steps;
  }

}
