<!--
<button mat-button [ngx-scroll-to]="'#title'"><span translate>sidenav.title</span></button><br>
<button mat-button [ngx-scroll-to]="'#goals'"><span translate>sidenav.goals</span></button><br>
<button mat-button [ngx-scroll-to]="'#constraints'"><span translate>sidenav.constraints</span></button><br>
<button mat-button [ngx-scroll-to]="'#background'"><span translate>sidenav.background</span></button><br>
<button mat-button [ngx-scroll-to]="'#organisation'"><span translate>sidenav.organisation</span></button><br>
<button mat-button [ngx-scroll-to]="'#riddle'"><span translate>sidenav.structure</span></button><br>
<button mat-button [ngx-scroll-to]="'#financial'"><span translate>sidenav.conclusion</span></button><br>
<button mat-button [ngx-scroll-to]="'#segam'"><span translate>sidenav.segam</span></button><br>
<button mat-button (click)="displayHelpDialog()"><span translate>sidenav.help</span></button><br>
<button mat-button (click)="displayContactDialog()"><span translate>sidenav.contact</span></button><br>

-->
<div class="sidenavButtons">

  <div class="loginButtons">
    <!--
    <div class="connect">
      <button mat-button *ngIf="!authService.isAuth" routerLink="/login"><span
          translate>sidenav.connexion</span></button>
      <button mat-button *ngIf="authService.isAuth" routerLink="/login" (click)="openDisconnectDialog()">Se
        déconnecter</button>
    </div>


    <button mat-button *ngIf="!authService.isAuth" routerLink="/login"><span translate>sidenav.subscribe</span></button>
    <button mat-button *ngIf="authService.isAuth" routerLink="/account">Bienvenue</button>
    -->
  </div>
</div>

<div>
  <!--<button mat-button *ngIf="!authService.isAuth" routerLink="/project"><span translate>sidenav.project</span></button>
  <button mat-button *ngIf="authService.isAuth" routerLink="/dashboard">Tableau de bord</button>-->
  <button mat-button routerLink="/"><span translate>sidenav.project</span></button>

  <br>

  <!-- Apparaît à la place de l'onglet 'Projet' lorsque l'utilisateur est connecté
<button mat-button routerLink="/dashboard"><span translate>sidenav.dashboard</span></button><br>
  -->
  <button mat-button routerLink="/help"><span translate>sidenav.help</span></button><br>
  <button mat-button routerLink="/about"><span translate>sidenav.about</span></button><br>
  <!--<button mat-button routerLink="/contact"><span translate>sidenav.contact</span></button>--><br>
</div>


<div id="languageSelector">
  <a (click)="selectNewLanguage()">
    <img [src]=flagForDisplay alt="flag" id="languageImage">
  </a>
</div>

<!--Placer Mentions Légales sous les langues d'où présence de la <div>-->
<div><button mat-button routerLink="/legal"><span translate>sidenav.legal</span></button><br></div>