<mat-expansion-panel>
  <mat-expansion-panel-header>
    <mat-panel-title>
      <span translate>sidenav.organisation</span>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <mat-grid-list cols="4" rowHeight="4em">
    <mat-grid-tile>
      <mat-form-field>
        <input matInput type="text" [(ngModel)]="organisation.projectTeam" placeholder="{{'organisation.team' | translate}}">
      </mat-form-field>
    </mat-grid-tile>
  </mat-grid-list>
  <mat-card class=" subTiles organisationCard">
    <mat-card-header>
      <mat-card-title translate>
        <span translate>organisation.tests.title</span>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div *ngFor="let test of organisation.tests; let i=index" style="flex-direction: column; box-sizing: border-box; display: flex;">
        <div style="flex-direction: row; box-sizing: border-box; display: flex; place-content: center space-around; align-items: center;">
          <mat-form-field>
            <input matInput type="date" placeholder="{{'organisation.tests.date' | translate}}" [(ngModel)]="test.date">
          </mat-form-field>
          <mat-form-field>
            <input matInput type="number" placeholder="{{'organisation.tests.testers' | translate}}" [(ngModel)]="test.testerNumber">
          </mat-form-field>
          <mat-form-field>
            <textarea matInput type="text" placeholder="{{'organisation.tests.feedback' | translate}}" [(ngModel)]="test.feedback"></textarea>
          </mat-form-field>      
          <button class="buttonSup" (click)="removeTest(i)">-</button>
        </div>
      </div>
      <button mat-raised-button color="primary" (click)="addTest()"><span translate>organisation.buttons.addTest</span></button>
    </mat-card-content>
  </mat-card>
  <mat-card class="subTiles organisationCard">
    <mat-card-header>
      <mat-card-title>
       <span translate>organisation.reengineering.title</span>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div *ngFor="let engineering of organisation.rengineering; let i=index" style="flex-direction: column; box-sizing: border-box; display: flex;">
        <div style="flex-direction: row; box-sizing: border-box; display: flex; place-content: center space-around; align-items: center;">
          <mat-form-field>
            <textarea matInput type="text" [(ngModel)]="engineering.modifications" placeholder="{{'organisation.reengineering.modifications' | translate}}"></textarea>
          </mat-form-field>
          <mat-form-field>
            <textarea matInput type="text" [(ngModel)]="engineering.nextVersion" placeholder="{{'organisation.reengineering.evolutions' | translate}}"></textarea>
          </mat-form-field>
          <button class="buttonSup" (click)="removeRengineering(i)">-</button>
        </div>
      </div>
      <button mat-raised-button color="primary" (click)="addRengineering()"><span translate>organisation.buttons.addNote</span></button>
    </mat-card-content>
  </mat-card>
</mat-expansion-panel>
