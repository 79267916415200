import {Component, Input, OnInit} from '@angular/core';
import {Riddle} from '../Models/Riddle';
import {StructureSection} from '../Models/StructureSection';
import {Clue} from '../Models/Clue';
import {TranslateService} from '@ngx-translate/core';
import { Skill } from '../Models/Skill';

@Component({
  selector: 'app-structure-section',
  templateUrl: './structure-section.component.html',
  styleUrls: ['./structure-section.component.css']
})
export class StructureSectionComponent {

  @Input() riddleSection: StructureSection;
  @Input() goalSkills: Skill[];

  constructor(private translate: TranslateService) {
  }

  ngOnInit() {

  }

  addFalseClue() {
    const newFalseClue = new Clue();
    newFalseClue.label = this.translate.instant('structure.fakeClue.label');
    this.riddleSection.falseClues.push(newFalseClue);
  }

  removeFalseClue(index) {
    this.riddleSection.falseClues.splice(index, 1);
  }

  addRiddle() {
    const newRiddle = this.riddleSection.createNewRiddle();
    newRiddle.label = this.translate.instant('structure.riddle.label.newLabel');
  }

  removeRiddle(index) {
    this.riddleSection.riddles.splice(index, 1);
  }
}
