import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-save-snackbar',
  templateUrl: './save-snackbar.component.html',
  styleUrls: ['./save-snackbar.component.css']
})
export class SaveSnackbarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
