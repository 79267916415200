<mat-grid-list cols="4" rowHeight="4em">
  <mat-grid-tile>
    <mat-form-field>
      <input matInput type="text" placeholder="{{'structure.riddle.label.placeholder' | translate}}" [(ngModel)]="riddle.label">
    </mat-form-field>
  </mat-grid-tile>
</mat-grid-list>

<mat-card class="subTiles">
  <mat-card-header>
    <mat-card-title translate>
      <span translate>structure.riddle.goal.title</span><span> {{riddle.answersTo.length}}</span>
    </mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <div *ngFor="let answerTo of riddle.answersTo; let i = index;" style="flex-direction: row; box-sizing: border-box; display: flex; place-content: stretch flex-start; align-items: baseline;">
      <mat-form-field>
        <mat-select placeholder="{{'structure.riddle.answersTo.placeholder' | translate}}" [(ngModel)]="answerTo.label">
          <mat-option *ngFor="let skill of goalSkills" value="{{skill.label}}">{{skill.label}}</mat-option>
        </mat-select>
      </mat-form-field>
      <button class="buttonSup" (click)="removeAnswerToGoal(i)">-</button>
    </div>
  </mat-card-content>
  <button mat-raised-button color="primary" type="button" (click)="addAnswerToGoal()">
    <span translate>structure.buttons.addGoal</span>
  </button>
</mat-card>
<br>


<mat-card class="subTiles">
  <mat-card-header>
    <mat-card-title>
      <span translate>structure.riddle.preRequisite.title</span>
    </mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <div *ngFor="let preRequisite of riddle.preRequisite; let i = index;" style="flex-direction: row; box-sizing: border-box; display: flex; place-content: center flex-start; align-items: center;">
      <mat-form-field>
        <mat-select placeholder="{{'structure.riddle.preRequisite.label.placeholder' | translate}}" [(ngModel)]="preRequisite.label">

          <ng-container *ngFor="let skill of goalSkills">
            <mat-option value='{{prerequisite.label}}' *ngFor="let prerequisite of skill.prerequisite">{{prerequisite.label}}</mat-option>
          </ng-container>

          <!--<mat-option *ngFor="let skill of goalSkills;" value="{{skill}}">{{skill.label}}</mat-option>-->


        </mat-select>
      </mat-form-field>
      <button class="buttonSup" (click)="removePreRequisite(i)">-</button>
    </div>
  </mat-card-content>
  <button mat-raised-button color="primary" (click)="addPreRequisite()"><span translate>structure.buttons.addPreRequisite</span></button>


  <!--
  <mat-card-content>

    <mat-grid-list cols="4" rowHeight="4em" *ngFor="let preRequisite of riddle.preRequisite;">
        <mat-form-field>
          <mat-select placeholder="{{'structure.riddle.preRequisite.label.placeholder' | translate}}">
            <ng-template *ngFor="let skill of goalSkills">
              <mat-option *ngFor="let preRequisite of skill.prerequisite" value="{{prerequisite}}">{{prerequisite.label}}</mat-option>
            </ng-template>
          </mat-select>
        </mat-form-field>
      <mat-grid-tile>
        <button class="buttonSup" (click)="removePreRequisite(preRequisite)">-</button>
      </mat-grid-tile>
    </mat-grid-list>

    <button mat-raised-button color="primary" (click)="addPreRequisite()"><span translate>structure.buttons.addPreRequisite</span></button>


  </mat-card-content>
  -->
</mat-card>

<div class="riddleSection" style="flex-direction: column; box-sizing: border-box; display: flex;">
  <mat-form-field>
    <textarea matInput placeholder="{{'structure.riddle.end.placeholder' | translate}}"
              [(ngModel)]="riddle.riddleReward"></textarea>
  </mat-form-field>
  <mat-form-field>
    <textarea matInput placeholder="{{'structure.riddle.help.placeholder' | translate}}"
              [(ngModel)]="riddle.help"></textarea>
  </mat-form-field>
  <mat-form-field>
    <mat-select placeholder="{{'structure.riddle.endsAt.placeholder' | translate}}" [(ngModel)]="riddle.endsAt">
      <mat-option *ngFor="let step of steps;" value="{{step}}">{{step}}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field>
    <input [(ngModel)]="riddle.color" matInput type="color" placeholder="{{'structure.riddle.color' | translate}}">
  </mat-form-field>
</div>

<mat-expansion-panel *ngFor="let clue of riddle.clues; let i = index">
  <mat-expansion-panel-header>
    <mat-panel-title>
      {{clue.label}}
    </mat-panel-title>
    <mat-panel-description>
      <mat-panel-title></mat-panel-title>
      <mat-icon (click)="removeClue(i)" class="mat-warn">delete</mat-icon>
    </mat-panel-description>
  </mat-expansion-panel-header>
  <app-clue-card [clue]="clue" [isTrueClue]="true" [stepCount]="riddle.endsAt"></app-clue-card>
</mat-expansion-panel>

<button class="add-button" mat-raised-button color="primary" (click)="addClue()">
  <span translate>structure.buttons.addClue</span>
</button>
