  <!--<mat-card-header>
    <mat-card-title><span translate>sidenav.help</span></mat-card-title>
    <mat-card-subtitle><span translate>help.sources</span>
    </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <mat-list>
      <mat-list-item>
        <a href="https://profpower.lelivrescolaire.fr/escape-game-pedagogique/" target="_blank"> How to create an educational escape game, tool box ! </a>
      </mat-list-item>
      <mat-list-item>
        <a href="https://scape.enepe.fr/-aide-a-la-creation-.html" target="_blank"> Studies and recommandations </a>
      </mat-list-item>
      <mat-list-item>
        <a href="https://www.ac-paris.fr/portail/jcms/p1_1640220/creer-un-escape-game-pedagogique" target="_blank"> Create an educational escape game </a>
      </mat-list-item>
      <mat-list-item>
        <a
          href="https://docs.google.com/spreadsheets/d/13enkwnK5KS4sKC0Zh_oGSXX1i0SAxo9EtxgvouYqU_8/edit#gid=1727415846" target="_blank"> Ressources to create an escape game </a>
      </mat-list-item>
    </mat-list>
    <mat-card-subtitle><span translate>help.articles</span></mat-card-subtitle>
    <mat-list>
      <mat-list-item>
        <a href="https://hal.archives-ouvertes.fr/hal-01503763" target="_blank">Escape Classroom : un escape game pour l'enseignement</a>
      </mat-list-item>
      <mat-list-item>
        <a href="https://hal.archives-ouvertes.fr/hal-01744860" target="_blank">A Model to Design Learning Escape Games : SEGAM</a>
      </mat-list-item>
    </mat-list>
  </mat-card-content>-->

  <h2 class="globalBloc">Aide : Trucs & Astuces</h2>

  <div class="globalBloc">
<mat-accordion>
  <mat-expansion-panel (opened)="panelOpenState = true"
                       (closed)="panelOpenState = false">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Phase de préparation/Préparatoires (en amont du jeu)
      </mat-panel-title>
    </mat-expansion-panel-header>

    <mat-accordion>
      <mat-expansion-panel (opened)="panelOpenState = true"
                           (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Phase d'autoformation
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>Afin d'éprouver ce à quoi vous souhaitez confronter votre cible - public, il peut être pertinent de tester un autre jeu de ce type (pédagogique ou non) en tant que joueur.
          Il existe de nombreux escape games récréatifs accessibles (généralement dans les grandes villes) qui proposent une multitude de thèmes et scénarios ainsi que des difficultés variées.
          Il est également pertinent de consulter des escape games pédagogiques dont certains sont testables en ligne. Voici deux exemples de sites qui en répertorient:
          <a href="https://www.cquesne-escapegame.com/les-jeux-d-evasion">Article 1</a> et <a href="https://scape.enepe.fr/-les-tresors-.html">Article 2</a>.</p>
      </mat-expansion-panel>
    </mat-accordion><br>

    <mat-accordion>
      <mat-expansion-panel (opened)="panelOpenState = true"
                           (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Phase de conception
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>Lorsque vous êtes prêt.e à débuter la conception de votre activité, vous pouvez suivre le formulaire de conception. Vous verrez ainsi l’ensemble des éléments à prendre en compte lors de la création d’un escape game pédagogique. Si vous souhaitez faire un escape game purement ludique ou un autre jeu à énigmes qui ne soit pas un escape game, alors ne choisissez que les champs qui vous intéressent. Cet outil vous permet également de voir si vos énigmes répondent bien aux objectifs pédagogiques que vous vous êtes fixés et d’avoir une représentation visuelle de la répartition de vos indices par énigme à travers les différentes étapes de l’activité.
        </p>
        <p>N’hésitez pas à varier les types d’énigmes pour plus de diversité. Essayez d’estimer le temps de résolution de chacune d’elles et combien de personnes sont nécessaires pour la résoudre. Si l’énigme prend trop de temps à résoudre, donnez plus d’indices, si elle n’en prend pas assez, essayez de la complexifier. </p>
      </mat-expansion-panel>
    </mat-accordion><br>

    <mat-accordion>
      <mat-expansion-panel (opened)="panelOpenState = true"
                           (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Phase de test (public/retours/modifs/incohérences)
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>Cette étape est PRIMORDIALE ! Ne passez pas à côté en pensant qu’il n’y a pas de raison que cela se passe mal, car il y aura toujours des correctifs, mêmes minimes, à effectuer avant la 1ere session.
        </p>
        <p>Pour de meilleurs tests, cherchez des testeurs similaires à votre public cible, les retours seront plus pertinents. Prenez des notes et notez chaque élément où les testeurs bloquent là où ils ne devraient pas, des éléments non intuitifs… Le retour de quelques testeurs n’est pas forcément représentatif de l’ensemble du public, mais si ces retours viennent une fois, d’autres joueurs peuvent avoir les mêmes retours. </p>
        <p>Vous pouvez ensuite classer ces retours en 2 catégories : les modifications faisables rapidement pour la prochaine session et celles qui prennent plus de temps et pourraient être réalisées pour une session plus lointaine.
        </p>
      </mat-expansion-panel>
    </mat-accordion>

  </mat-expansion-panel>
</mat-accordion><br>

  <mat-accordion>
    <mat-expansion-panel (opened)="panelOpenState = true"
                         (closed)="panelOpenState = false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Phase de jeu
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-accordion>
        <mat-expansion-panel (opened)="panelOpenState = true"
                             (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Phase d'introduction
            </mat-panel-title>
          </mat-expansion-panel-header>
          <h3>Règles de sécurité</h3>
          <p>Un rappel des règles est toujours utile, quelque soit le public pour ne pas avoir de mauvaise surprise : </p>
            <ul>
              <li>ne pas grimper sur les meubles, tout doit se trouver à portée</li>
              <li>ne rien casser, déchirer (sauf si cela fait partie de votre jeu, mais si vous souhaitez faire plusieurs sessions, il faut prévoir de multiplier ces éléments)</li>
              <li>ne pas forcer les objets, les cadenas (ils risqueraient de résoudre une énigme pour rien plus tard dans le jeu, et s’ils ne peuvent pas ouvrir un cadenas pour l’instant c’est probablement car ils n’ont pas les éléments nécessaires)</li>
            </ul>

          <h3>Consignes (dos and donts: icônes/zone de jeu)</h3>
          <ul>
            <li>Préciser de ne pas toucher aux prises et aux éléments informatiques non nécessaires (par exemple si vous utilisez un ordinateur pour diffuser un timer et de la musique mais non utiles pour la résolution d’énigmes)</li>
            <p>Conseil : vous pouvez également imprimer/dessiner des icônes signalant les éléments de la salle à ne pas toucher, où il ne faut pas écrire etc. pour rappel.</p>
            <li>Énoncez la délimitation de la zone de recherche et donner des repères clairs pour éviter qu’ils n’accèdent à des zones dangereuses ou inutiles qui leur fassent perdre du temps.</li>
          </ul>

          <h3>Immersion - sensibilisation</h3>
          <ul>
            <li>L’immersion est fondamentale pour les escape games, l’ambiance est donc un élément majeur à prendre en compte. Pour que les joueurs s’y plongent rapidement, n’hésitez pas à jouer le jeu, à théâtraliser vos interventions etc.</li>
            <li>Lors de l’introduction au jeu, vous allez donc raconter le début de l’histoire à votre public. Il est envisageable de leur envoyer un document au préalable pour qu’ils se plongent dans l’histoire avant la session.</li>
          </ul>

          <h3>Gestion des indices, du temps, de l’équipe</h3>
          <ul>
            <li>expliquez leur comment ils peuvent obtenir des indices :
              <ul>
                <li>en demandant directement à un maître du jeu dans la pièce ou à travers une caméra si avez ce type de dispositif, </li>
                <li>de manière limitée : donnez-leur alors des jetons ou des objets à donner au maître du jeu en échange d’un indice. Ils sauront ainsi à tout moment combien ils peuvent demander d’indices</li>
                <li>via un écran (télévision, message sur un ordinateur, un smartphone, une tablette, etc.) </li>
              </ul>
            </li>
            <li>Enfin, dites leur le temps qu’ils ont pour accomplir leur mission.</li>
            <li>Il s’agit d’un jeu en équipe, insistez sur l’importance de la communication entre eux dès qu’ils trouvent un élément dans la pièce, qu’ils résolvent une énigme, qu’ils récupèrent un indice pour que tous les membres soient au courant de tous les éléments à disposition.</li>
            <li>N’hésitez pas à insister sur l'importance de la fouille dès le début du jeu car afin d'éviter le manque d’implication des joueurs dans cette tâche par manque de temps ou d'assurance</li>
          </ul>

        </mat-expansion-panel>
      </mat-accordion><br>

      <mat-accordion>
        <mat-expansion-panel (opened)="panelOpenState = true"
                             (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Phase de gestion In-Game
            </mat-panel-title>
          </mat-expansion-panel-header>
          <h3>Du MJ (place)</h3>
          <ul>
            <li>Il est préférable que le maître du jeu soit en retrait pour ne pas gêner les joueurs dans leurs recherches et réflexions ou qu’ils soient tentés de demander des indices trop rapidement.</li>
            <li>Laissez les joueurs s’imprégner du jeu pendant une dizaine de minutes environ. Le rythme sera lancé lorsqu’ils réussiront à résoudre la 1ere énigme.</li>
          </ul>

          <h3>Outils de gestion énigmes</h3>
          <ul>
            <li>Pour une meilleure gestion de la session, il est fortement conseillé d’avoir une feuille récapitulative des codes à déverrouiller par énigme</li>
            <li>Pour simplifier la (re)mise en place, il est également conseillé d’avoir une fiche précisant où chaque élément est placé dans la pièce. Cela sera utile pour vous ou pour toute personne extérieure venant vous aider à gérer des sessions, non seulement pour placer les éléments mais aussi pour pouvoir aiguiller les joueurs s’ils leur manque un indice, vous saurez les guider vers l’élément en question sans soucis.</li>
          </ul>

          <h3>Gestion temps et du rythme (ie retours tests/ indices/ temps morts)</h3>
          <ul>
            <li>Essayez d’anticiper le temps qu’il faut pour résoudre chaque énigme. Vous pourrez ainsi savoir à quel moment donner des indices pour aider une équipe pour qu’ils aient encore un espoir de gagner.</li>
            <li>Pour une meilleure immersion, vous pouvez donner des indices en jouant sur les mots ou en incluant des éléments du scénario. Par exemple :  « Les indices sont SOUS vos yeux » pour parler d’un indice caché sous un objet.</li>
            <li>Veillez à ce que tous les joueurs aient entendu et compris vos indices s’ils ne sont pas tous à portée lorsque vous les énoncez.</li>
          </ul>

          <h3>Gestion de l'échec (favoriser ou non)</h3>
          <p>Un escape game est avant tout un jeu, et une des propriétés du jeu est que l’issue est incertaine, il n’est donc pas grave si une ou plusieurs équipes échouent près de la fin, il est préférable cependant qu’ils aient atteint la dernière énigme. Si toutefois toutes vos équipes échouent, il faudra sûrement revoir soit la difficulté de vos énigmes, soit la durée de jeu ;) </p>

          <h3>Gestion de l'espace (ie retours tests/ comment baliser)</h3>
          <p>Veillez à bien délimiter l’espace de la zone de jeu et à l’adapter en fonction du nombre de joueurs et des besoins au niveau du matériel, du décor. Ceci pour éviter la perte de temps lors de la recherche d’indices.</p>
        </mat-expansion-panel>
      </mat-accordion><br>

      <mat-accordion>
        <mat-expansion-panel (opened)="panelOpenState = true"
                             (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Phase de conclusion
            </mat-panel-title>
          </mat-expansion-panel-header>
          <h3>Conclure le scénario (immersion)</h3>
          <p>Lorsque le jeu se termine, n’oubliez pas de conclure l’histoire dans laquelle vos joueurs sont plongés depuis de longues minutes ! </p>

          <h3>Instaurer la compétition (triche aux résultats)</h3>
          <p>Si vous souhaitez éviter la triche et que votre public communique sur la résolution des énigmes, vous pouvez stimuler l'esprit de compétition des joueurs à l'aide d'un système de récompense par classement</p>

        </mat-expansion-panel>
      </mat-accordion>

    </mat-expansion-panel>
  </mat-accordion><br>



  <mat-accordion>
    <mat-expansion-panel (opened)="panelOpenState = true"
                         (closed)="panelOpenState = false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Phase de débriefing (après le jeu)
        </mat-panel-title>

      </mat-expansion-panel-header>

          <h3>Débriefing (expliquer la résolution/ faire le lien avec le cours/ exemples de débriefing)</h3>

          <p>Il est important de prévoir un temps après le jeu pour revenir sur les énigmes vues durant la session. Cela va permettre aux joueurs n’ayant pas pu s’attarder sur ces éléments d’avoir une meilleure vision d’ensemble et prendre connaissance de la totalité des énigmes.
            S’il s’agit d’un jeu pédagogique, c’est le moment où vous allez mettre en lumière les éléments de cours dissimulés derrière les énigmes, expliquer les notions si elles ont posé problème, donner du sens à leur apprentissage.
            Vous pouvez le faire de plusieurs manières, en voici quelques unes :
          </p>
          <ul>
            <li>rapidement, en évoquant les énigmes</li>
            <li>en reprenant chaque énigme sur les lieux de la session et en montrant comment résoudre l’énigme</li>
            <li>avec un support (audio)visuel détaillant la résolution des énigmes</li>
            <li>avec une vidéo mettant en scène la résolution de l'EG à laquelle vous pourrez ajouter vos remarques et commentaires et adapter le rythme pour une compréhension plus optimale.</li>
          </ul>

          <h3>Rangement (logistique)</h3>

          <p>Pensez au temps de rangement, entre 2 sessions ou à la fin de votre session. Essayez de minimiser les éléments à replacer et à optimiser les éléments tangibles pour les transporter et stocker plus facilement</p>

    </mat-expansion-panel>
  </mat-accordion><br>

  <mat-accordion>
  <mat-expansion-panel (opened)="panelOpenState = true"
                       (closed)="panelOpenState = false">
    <mat-expansion-panel-header>
      <mat-panel-title>
      Sources
      </mat-panel-title>
    </mat-expansion-panel-header>

    <mat-accordion>
      <mat-expansion-panel (opened)="panelOpenState = true"
                           (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Français - French
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ul>
          <li>Kit de l’escape game Recherche à risque, créé par Délires d’encre et Science Animation dans le cadre de la Fête de la Science 2019 - Guide du maître du jeu.
          <ul>
            <a href="https://www.fetedelascience.fr/pid35151-cid145131/recherche-a-risque-l-escape-game-2019.html"><li>https://www.fetedelascience.fr/pid35151-cid145131/recherche-a-risque-l-escape-game-2019.html</li></a>
          </ul>
          </li><br>
          <li>Gaëlle Guigon, Jérémie Humeau, Mathieu Vermeulen. Escape Classroom : un escape game pour l’enseignement. 9ème Colloque Questions de Pédagogie dans l'Enseignement Supérieur (QPES 2017), Jun 2017, Grenoble, France. ⟨hal-01503763⟩
          <ul>
            <a href="https://hal.archives-ouvertes.fr/hal-01503763"><li>https://hal.archives-ouvertes.fr/hal-01503763</li></a>
          </ul><br>
          </li>
          <li>Vanessa Marescot, Gaëlle Guigon, Mathieu Vermeulen. Inside Memory : un Serious Escape Game pour Apprendre à Apprendre. Questions de Pédagogies dans l'Enseignement Supérieur, ENSTA Bretagne, IMT-A, UBO, Jun 2019, Brest, France. ⟨hal-02284009⟩
          <ul>
            <a href="https://hal.archives-ouvertes.fr/hal-02284009"><li>https://hal.archives-ouvertes.fr/hal-02284009</li></a>
          </ul>
          </li>
        </ul>
      </mat-expansion-panel>
    </mat-accordion><br>

    <mat-accordion>
      <mat-expansion-panel (opened)="panelOpenState = true"
                           (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Anglais - English
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ul>
          <li>Gaëlle Guigon, Jérémie Humeau, Mathieu Vermeulen. A Model to Design Learning Escape Games: SEGAM. 10th International Conference on Computer Supported Education, Mar 2018, Funchal, Madeira, Portugal. pp.191-197, ⟨10.5220/0006665501910197⟩. ⟨hal-01744860⟩
          <ul>
            <a href="https://hal.archives-ouvertes.fr/hal-01744860v1"><li>https://hal.archives-ouvertes.fr/hal-01744860v1</li></a>
          </ul>
          </li><br>
          <li>Gaëlle Guigon, Mathieu Vermeulen, Jérémie Humeau. A Creation Tool for Serious Puzzle Games. CSEDU 2019, May 2019, Heraklion, Greece. pp.556-561, ⟨10.5220/0007796405560561⟩. ⟨hal-02132554⟩
          <ul>
            <a href="https://hal.archives-ouvertes.fr/hal-02132554v1"><li>https://hal.archives-ouvertes.fr/hal-02132554v1</li></a>
          </ul>
          </li><br>
          <li>Hugo Brument, Rebecca Fribourg, Gerard Gallagher, Thomas Howard, Flavien Lécuyer, et al.. Pyramid Escape: Design of Novel Passive Haptics Interactions for an Immersive and Modular Scenario. VR 2019 - 26th IEEE Conference on Virtual Reality and 3D User Interfaces, Mar 2019, Osaka, Japan. pp.1409-1410, ⟨10.1109/VR.2019.8797848⟩. ⟨hal-02284129v2⟩
          <ul>
            <a href="https://hal.archives-ouvertes.fr/hal-02284129"><li>https://hal.archives-ouvertes.fr/hal-02284129</li></a>
          </ul><br>
          <li>Nicolas Dietrich. Escape Classroom: The Leblanc Process-An Educational "Escape Game". Journal of Chemical Education, American Chemical Society, Division of Chemical Education, 2018, 95 (6), pp.996-999. ⟨10.1021/acs.jchemed.7b00690⟩. ⟨hal-01886491⟩
            <ul>
              <a href="https://hal.archives-ouvertes.fr/hal-01886491"><li>https://hal.archives-ouvertes.fr/hal-01886491</li></a>
            </ul>
        </ul>
      </mat-expansion-panel>
    </mat-accordion><br>

  </mat-expansion-panel>
  </mat-accordion><br>

    <mat-accordion>
      <mat-expansion-panel (opened)="panelOpenState = true"
                           (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
           FAQ
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ul>
          <mat-accordion>
            <mat-expansion-panel (opened)="panelOpenState = true"
                                 (closed)="panelOpenState = false">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Qu'entend-on par Serious Escape Game ?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <ul>
                <p>Serious : intention de faire passer un message : éducatif, team building, commercial, écologique, politique, autre.</p>
                <li>Escape Game :
                <ul>
                  <li>de base, les escape games sont des “jeux d’évasions” où il faut donc sortir d’une pièce pour gagner la partie. Pour diversifier ces activités, d’autres buts sont envisageables : trouver un antidote, sauver le monde, désamorcer une bombe, etc. </li>
                  <li>des indices et des énigmes ! Pour pouvoir atteindre le but final, il faut résoudre des énigmes en utilisant des indices dissimulés dans l’espace défini.</li>
                  <li>un temps limité : généralement sous le format d’une heure, le temps peut varier pour correspondre au contexte, au public, aux objectifs pédagogiques…</li>
                  <li>en équipe ou en solo : la plupart du temps, les escape games physiques sont conçus pour que plusieurs joueurs se répartissent les tâches et coopèrent. Toutefois, si votre escape game est totalement virtuel, ou s’il est conçu pour 1 personne, alors cela est tout à fait réalisable.</li>
                </ul>
                </li>
              </ul>
            </mat-expansion-panel>
          </mat-accordion><br>

          <mat-accordion>
            <mat-expansion-panel (opened)="panelOpenState = true"
                                 (closed)="panelOpenState = false">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Y a-t-il un niveau scolaire minimum pour faire jouer un SEG dans sa classe ?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <ul>
                <p>Non, après chaque enseignant va estimer en fonction du niveau de sa classe si le public sera réceptif à ce type d’activité. Mais plusieurs escape games ont été créés pour différents niveaux, de la maternelle aux études supérieures.</p>
                <li> Vous pouvez retrouver des exemples ici :
                  <ul>
                    <a href="https://scape.enepe.fr/la-carte-aux-tresors.html"><li>https://scape.enepe.fr/la-carte-aux-tresors.html</li></a>
                    <a href="https://www.cquesne-escapegame.com/les-escape-games-pedagogiques"><li>https://www.cquesne-escapegame.com/les-escape-games-pedagogiques</li></a>
                  </ul>
                </li>
              </ul>
            </mat-expansion-panel>
          </mat-accordion><br>

          <mat-accordion>
            <mat-expansion-panel (opened)="panelOpenState = true"
                                 (closed)="panelOpenState = false">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Faut-il un budget minimum pour créer un escape game ?
                </mat-panel-title>
              </mat-expansion-panel-header>
                <p>Non, le budget va limiter vos possibilités, mais vous pouvez créer un escape game gratuitement avec des supports numériques, ou vous pouvez en créer un avec du matériel de récupération. Le budget vous permettra principalement d’acquérir des cadenas ou objets non usuels.</p>
            </mat-expansion-panel>
          </mat-accordion><br>

          <mat-accordion>
            <mat-expansion-panel (opened)="panelOpenState = true"
                                 (closed)="panelOpenState = false">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Puis-je créer un escape game seul.e ?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <p>Oui, vous pouvez le concevoir seul.e, et cet outil est là pour vous aider dans cette démarche. Par contre il est fortement conseillé de faire tester votre activité à d’autres personnes : proches du public cible ainsi qu’à des pairs, pour avoir du recul sur votre SEG et pouvoir recueillir des avis extérieurs, percevoir d’autres modes de réflexion sur vos énigmes.</p>
            </mat-expansion-panel>
          </mat-accordion><br>

          <mat-accordion>
            <mat-expansion-panel (opened)="panelOpenState = true"
                                 (closed)="panelOpenState = false">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Combien de temps faut-il pour créer un escape game ?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <p>Cela dépend de la durée de l’escape game, de la disponibilité du groupe projet et de l’imagination des membres. Comptez facilement 1 mois de conception, il est conseillé de faire le.s test.s au moins 15 jours avant la 1ere session pour avoir le temps de corriger les petits bugs</p>
            </mat-expansion-panel>
          </mat-accordion><br>

        </ul>
      </mat-expansion-panel>
    </mat-accordion>




  </div>
