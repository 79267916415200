import {Injectable} from '@angular/core';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {Location} from '../Models/Location';

@Injectable({
  providedIn: 'root'
})
export class SegamFormService {

  get segamForm(): FormGroup {
    return this._segamForm;
  }
  private _segamForm: FormGroup;

  constructor(private formBuilder: FormBuilder) {
    this._segamForm = this.formBuilder.group({
      // Goal Group contains all information about SEGAM goal
      goalGroup: formBuilder.group({
        generalGoal: '',
        target: '',
        skills: new FormArray([]),
      }),
      // Constraint group contains all informations about SEGAM constraints
      constraintGroup: formBuilder.group({
        activityDuration: 0,
        nbOfSessions: 0,
        nbOfStudents: 0,
        nbPerGroup: 0,
        simultaneousGroup: 0,
        location: new Location(),
        budget: 0,
        teachers: '',
        wifi: false,
        data: false,
        otherConstraints: '',
      }),
      backgroundGroup: formBuilder.group({
        character: new FormArray([]),
        decorations: new FormArray([]),
      }),
      // Tester and Engineering Groups are both part of the organisation group
      testerGroup: formBuilder.group({
        date: new FormArray([]),
        numberOfTesters: new FormArray([]),
        feedback: new FormArray([]),
      }),
      reengineeringGroup: formBuilder.group({
        modifications: new FormArray([]),
        nextVersionNeeds: new FormArray([]),
      }),
      riddleGroup: formBuilder.group({
        stepsNb: 0,
        riddles: new FormArray([]),
        falseClues: new FormArray([]),
      }),
      structureGroup: formBuilder.group({}),
      segamGroup: formBuilder.group({})
    });
  }

}
