import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Constraints } from '../Models/Constraints';

@Component({
  selector: 'app-constraint-section',
  templateUrl: './constraint-section.component.html',
  styleUrls: ['./constraint-section.component.css']
})
export class ConstraintSectionComponent implements OnInit {
  @Input() constraint: Constraints;
  selectedFile = null;
  constructor() { }

  ngOnInit() {
  }

  onFileSelected(event){

    this.selectedFile = event.target.files[0];
    /**
     * le chemin du fichier est chargé. Voir ou Upload ce fichier par la suite
     */
  }

}
