import { Background } from "./Background";
import { Constraints } from "./Constraints";
import { Goal } from "./Goal";
import { Organisation } from "./Organisation";
import { StructureSection } from "./StructureSection";

export class Project {
    projectName: string = '';
    goal: Goal = new Goal();
    constraints = new Constraints();
    background = new Background();
    organisation = new Organisation();
    structure = new StructureSection();
}
