import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {SegamFormService} from '../shared/segam-form.service';
import {FormArray, FormBuilder} from '@angular/forms';
import { Goal } from '../Models/Goal';
import { Skill } from '../Models/Skill';

@Component({
  selector: 'app-goal-section',
  templateUrl: './goal-section.component.html',
  styleUrls: ['./goal-section.component.css']
})
export class GoalSectionComponent implements OnInit {
  @Input() goal: Goal;
  public goalFormGroup;

  constructor(public translate: TranslateService, private segamFormService: SegamFormService,
              private formBuilder: FormBuilder) {}

  ngOnInit() {
    //this.goalFormGroup = this.segamFormService.segamForm.get('goalGroup');
  }

  createSkill() {
    this.goal.skills.push(new Skill());
    /*
    return this.formBuilder.group({
      label: this.translate.instant('goals.newSkill.label'),
      priority: '',
      prerequisite: new FormArray([]),
    });
    */
  }

  addSkill() {
    //const skills = this.goalFormGroup.get('skills') as FormArray;
    //skills.push(this.createSkill());
    this.createSkill();
  }

  removeSkill(index) {
    //const skills = this.goalFormGroup.get('skills') as FormArray;
    //skills.removeAt(index);
    this.goal.skills.splice(index,1);
    //console.log(skills);
  }
}
