import { Component, OnInit, HostListener, Input } from '@angular/core';
import { Riddle } from '../Models/Riddle';
import { StructureSection } from '../Models/StructureSection';

@Component({
  selector: 'app-segam-preview',
  templateUrl: './segam-preview.component.html',
  styleUrls: ['./segam-preview.component.css']
})
export class SegamPreviewComponent {

  height = 100;
  width = 100;
  right = 20;
  bottom = 20;

  resize = false;

  @Input() riddleSection: StructureSection


  constructor() {

  }

  @HostListener('document:mousemove', ['$event'])
  onMove(event) {
    if (this.resize) {
      this.height = window.innerHeight - event.y - this.bottom;
      this.width = window.innerWidth - event.x - this.right;
    }

    event.preventDefault();
  }

  @HostListener('document:mouseup', ['$event'])
  onMouseUp(event) {
    this.resize = false;
  }

  onMouseDown(event) {
    this.resize = true;
  }

}
