<mat-form-field appearance="outline">
  <mat-label>Votre projet</mat-label>
  <input matInput placeholder="Projet 1">
</mat-form-field>
<p>Photo :</p>
<mat-icon>insert_photo</mat-icon><button mat-button mat-dialog>Selectionner</button>
<p>Partager :</p>
<form class="example-form">
  <mat-form-field class="example-full-width">
    <mat-label></mat-label>
    <input matInput placeholder="" value="jonhdoe@mail.fr">
  </mat-form-field>

  <p><mat-icon>add</mat-icon></p>

  <button mat-button mat-dialog-close>Partager le projet</button>

  <mat-form-field>
    <mat-label>Droit</mat-label>
    <select matNativeControl required>
      <option></option>
      <option>Lecture</option>
      <option>Modifications</option>
      <option>Administrateur</option>
    </select>
  </mat-form-field>
