import { Injectable } from '@angular/core';
import { Project } from '../Models/Project';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  project: Project = new Project();

  constructor() { }
}
