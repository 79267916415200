import {Component, Input, OnInit, EventEmitter, Output} from '@angular/core';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { GoalPreRequisite } from 'src/app/Models/GoalPreRequisite';
import { Skill } from 'src/app/Models/Skill';

@Component({
  selector: 'app-goal-subsection',
  templateUrl: './goal-subsection.component.html',
  styleUrls: ['./goal-subsection.component.css']
})
export class GoalSubsectionComponent implements OnInit {

  @Input() skill: Skill;
  @Input() index: number;

  @Output() close: EventEmitter<any> = new EventEmitter();

  constructor(private fb: FormBuilder, public translate: TranslateService) {
  }

  ngOnInit() {
    //const prerequisite = this.skill.get('prerequisite') as FormArray;
    //prerequisite.push(this.addPreRequisiteGroup());
  }

  addPreRequisiteGroup() {
    /*
    return this.fb.group({
      preRequisiteLabel: '',
    });
    */
  }

  addPreRequisite() {
    //this.preRequisiteArray.push(this.addPreRequisiteGroup());
    this.skill.prerequisite.push(new GoalPreRequisite());
  }

  removePreRequisite(index) {
    //this.preRequisiteArray.removeAt(index);
    this.skill.prerequisite.splice(index,1)
  }

  removeSkill() {
    this.close.emit(this.index);
  }

  /*
  get preRequisiteArray() {
    //return <FormArray>this.skill.get('prerequisite');
  }
    */
}
