<mat-expansion-panel>
  <mat-expansion-panel-header>
    <mat-panel-title>
      <span translate>sidenav.structure</span>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <mat-grid-list cols="4" rowHeight="4em">
    <mat-grid-tile>
      <mat-form-field>
        <input matInput type="number" [(ngModel)]="riddleSection.stepsNb"
               placeholder="{{'structure.stepsnb' | translate}}">
      </mat-form-field>
    </mat-grid-tile>
  </mat-grid-list>

  <mat-accordion>
    <mat-expansion-panel *ngFor="let riddle of riddleSection.riddles; let i = index;">

      <mat-expansion-panel-header>
        <mat-panel-title>
          {{riddle.label}}
        </mat-panel-title>
        <mat-panel-description>
          <mat-panel-title></mat-panel-title>
          <mat-icon (click)="removeRiddle(i)" class="mat-warn">delete</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <app-structure-subsection [riddle]="riddle" [stepCount]="riddleSection.stepsNb" [goalSkills]="goalSkills">
      </app-structure-subsection>
    </mat-expansion-panel>
  </mat-accordion>

  <div class="add-button">
    <button mat-raised-button color="primary" type="button" (click)="addRiddle()">
      <span translate>structure.buttons.addRiddle</span>
    </button>
  </div>

  <mat-expansion-panel *ngFor="let falseClue of riddleSection.falseClues; let i = index">
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{falseClue.label}}
      </mat-panel-title>
      <mat-panel-description>
        <mat-panel-title></mat-panel-title>
        <mat-icon (click)="removeFalseClue(i)" class="mat-warn">delete</mat-icon>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <app-clue-card [clue]="falseClue" [isTrueClue]="false" [stepCount]="riddleSection.stepsNb"></app-clue-card>
  </mat-expansion-panel>

  <div class="add-button">
    <button mat-raised-button color="primary" (click)="addFalseClue()">
      <span translate>structure.buttons.addFakeClue</span>
    </button>
  </div>
</mat-expansion-panel>



