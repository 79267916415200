import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-legal-section',
  templateUrl: './legal-section.component.html',
  styleUrls: ['./legal-section.component.css']
})
export class LegalSectionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
