<mat-expansion-panel expanded>
  <mat-expansion-panel-header>
    <mat-panel-title>
      <span translate>sidenav.goals</span>
    </mat-panel-title>
  </mat-expansion-panel-header>

  <mat-form-field>
    <input matInput type="text" [(ngModel)]="goal.generalGoal" placeholder="{{'goals.general' | translate }}">
  </mat-form-field>
  <br>
  <mat-form-field>
    <input matInput [(ngModel)]="goal.target" type="text" placeholder="{{'goals.target' | translate}}">
  </mat-form-field>
  <br>
  <mat-accordion *ngIf="goal.skills.length > 0">
    <mat-expansion-panel *ngFor="let skill of goal.skills; let i = index;">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{skill.label}}
          {{!skill.label ? translate.instant('goals.newSkill.label') + ' ' + (i + 1) : ''}}
        </mat-panel-title>
        <mat-panel-description>
          <mat-panel-title></mat-panel-title>
          <mat-icon (click)="removeSkill(i)" class="mat-warn">delete</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <app-goal-subsection [skill]="skill" [index]="i" (close)="removeSkill($event)"></app-goal-subsection>
    </mat-expansion-panel>
  </mat-accordion>
  <button mat-raised-button color="primary" type="button" (click)="addSkill()" class="add-button"><span translate>goals.buttons.addSkill</span>
  </button>
</mat-expansion-panel>

