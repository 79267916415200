import {Component, Input, OnInit} from '@angular/core';
import {Background} from '../Models/Background';
import {FormBuilder, FormGroup, FormArray} from '@angular/forms';
import {Character} from '../Models/Character';
import {Decoration} from '../Models/Decoration';

@Component({
  selector: 'app-background-section',
  templateUrl: './background-section.component.html',
  styleUrls: ['./background-section.component.css']
})
export class BackgroundSectionComponent implements OnInit {
  @Input() background: Background;


  constructor() {
  }

  ngOnInit() {
    this.background.character = [];
    this.background.decorations = [];
  }

  addCharacter() {
    this.background.character.push(new Character());
  }

  removeCharacter(index) {
    this.background.character.splice(index, 1);
  }

  addDecoration() {
    this.background.decorations.push(new Decoration());
  }

  removeDecoration(index) {
    this.background.decorations.splice(index, 1);
  }

  indexTracker(index: number, obj: any): any {
    return index;
  }


}
