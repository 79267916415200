import {Character} from './Character';
import { Decoration } from './Decoration';

export class Background {
  finalGoal: string;
  story: string;
  failureConsequences: string;
  character: Character[];
  socialModel: string;
  decorations: Decoration[];

}
