import {Location} from './Location';

export class Constraints {
  activityDuration: number;
  nbOfSessions: number;
  nbOfStudents: number;
  nbPerGroup: number;
  simultaneousGroup: number;
  location: Location = new Location();
  budget: number = 0;
  teachers: string;
  wifi: boolean;
  data: boolean;
  otherConstraints: string;
}
