import {Component, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {NavigationEnd, Router, Scroll} from '@angular/router';
import {filter} from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import {LanguagePickerModalComponent} from '../language-picker-modal/language-picker-modal.component';
import {TranslateService} from '@ngx-translate/core';
import { HelpSectionComponent } from '../help-section/help-section.component';
import {AuthService} from '../auth.service';
import {DisconnectModalComponent} from '../Modals/disconnect-modal/disconnect-modal.component';
import {LoginSnackbarComponent} from '../login-snackbar/login-snackbar.component';
import {MatSnackBar} from "@angular/material/snack-bar";
import {DiscoSnackbarComponent} from "../disco-snackbar/disco-snackbar.component";

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  durationInSecond: 5;

  public flagForDisplay: string;

  // tslint:disable-next-line:max-line-length
  constructor(private dialog: MatDialog, private translate: TranslateService, public authService: AuthService, private _snackBar: MatSnackBar) {

  }

  ngOnInit() {
    this.setFlag(this.translate.getDefaultLang());

    this.translate.onLangChange
      .subscribe(event => this.setFlag(event.lang));
  }

  selectNewLanguage() {
    const dialogRef = this.dialog.open(LanguagePickerModalComponent, {
      height: '25em',
      width: '50em',
    });

    dialogRef.afterClosed().subscribe(languageSelected => {
      if (languageSelected) {
        this.translate.use(languageSelected);
      }
    });
  }

  setFlag(lang) {
    this.flagForDisplay = `assets/img/flags/${lang}.png`;
  }

  displayHelpDialog() {
    this.dialog.open(HelpSectionComponent, {
      height: 'fit-content',
      width: 'fit-content',
    });
  }

  displayContactDialog() {
    this.dialog.open(HelpSectionComponent, {
      height: 'fit-content',
      width: 'fit-content',
    });
  }

  openDisconnectDialog() {
    this.dialog.open(DisconnectModalComponent);
  }

  openSnackBarDisco() {
    this._snackBar.openFromComponent(DiscoSnackbarComponent, {
      duration: this.durationInSecond * 1000,
    });
  }
}
