import {Component, Input, OnInit} from '@angular/core';
import {Organisation} from '../Models/Organisation';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import { Test } from '../Models/Test';
import { Rengineering } from '../Models/Rengineering';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-organisation-section',
  templateUrl: './organisation-section.component.html',
  styleUrls: ['./organisation-section.component.css']
})
export class OrganisationSectionComponent implements OnInit {
  @Input() organisation: Organisation;
  testForm: FormGroup;
  rengineeringForm: FormGroup;

  constructor(private fb: FormBuilder, public translate: TranslateService) {
  }

  ngOnInit() {
    /*
    this.testForm = this.fb.group({
      test: this.fb.array([this.addTestGroup()])
    });
    this.rengineeringForm = this.fb.group({
      rengineering: this.fb.array([this.addRengineeringGroup()])
    });
    */
  }

  addTestGroup() {
    /*
    return this.fb.group({
      date: [],
      numberOfTesters: [],
      feedback: []
    });
    */
  }

  addTest() {
    //this.testArray.push(this.addTestGroup());
    this.organisation.tests.push(new Test());
  }

  removeTest(index) {
    //this.testArray.removeAt(index);
    this.organisation.tests.splice(index,1);
  }

  /*
  get testArray() {
    return <FormArray>this.testForm.get('test');
  }
  */

  addRengineeringGroup() {
    /*
    return this.fb.group({
      modifications: [],
      nextVersionNeeds: []
    });
    */
  }

  addRengineering() {
    //this.rengineeringArray.push(this.addRengineeringGroup());
    this.organisation.rengineering.push(new Rengineering());
  }

  removeRengineering(index) {
    //this.rengineeringArray.removeAt(index);
    this.organisation.rengineering.splice(index,1);
  }

  /*
  get rengineeringArray() {
    return <FormArray>this.rengineeringForm.get('rengineering');
  }
  */
}
