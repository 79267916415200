<div class="matCardPosition">
<mat-card>
  <mat-card-title>Se connecter</mat-card-title><br>
  <mat-card-content>
    <p>
      <mat-form-field appearance="outline" class="inputSize">
        <mat-label>Pseudonyme</mat-label>
        <input matInput placeholder="Ex. John Doe">
      </mat-form-field>
    </p>
      <mat-form-field appearance="outline" class="inputSize">
        <mat-label>Mot de passe</mat-label>
        <input matInput placeholder="8 car. min" [type]="hide ? 'password' : 'text'" formControlName="password" required>
        <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
        <mat-hint>Mot de passe oublié ?</mat-hint>
      </mat-form-field>
    <section><br>
      <div class="example-button-row">
        <button mat-button color="primary" (click)="authService.authUser()" routerLink="/account" (click)="openSnackBarLog()">Connexion</button>
<!--        {{authService.isAuth}}-->
      </div>
    </section>
  </mat-card-content>
</mat-card><br>

<mat-card class="subscribe">
  <mat-card-title>S'inscrire</mat-card-title><br>
  <mat-card-content>
    <p>
      <mat-form-field appearance="outline" class="inputSize">
        <mat-label>Pseudonyme</mat-label>
        <input matInput placeholder="Ex. John Doe">
        <mat-hint>Un email de confirmation sera envoyé</mat-hint><br>
      </mat-form-field>
    </p>
    <p>
      <mat-form-field appearance="outline" class="inputSize">
        <mat-label>E-mail</mat-label>
        <input matInput placeholder="Ex. johndoe@mail.com">
      </mat-form-field>
    </p>
    <p>
      <mat-form-field appearance="outline" class="inputSize">
        <mat-label>Mot de passe</mat-label>
        <input matInput placeholder="8 car. min" [type]="hide ? 'password' : 'text'" formControlName="password" required>
        <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
      </mat-form-field>
    </p>
    <p>
      <mat-form-field appearance="outline" class="inputSize">
        <mat-label>Confirmation du mot de passe</mat-label>
        <input matInput placeholder="Identique au premier" [type]="hide ? 'password' : 'text'" formControlName="password" required>
        <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
      </mat-form-field>
    </p>
    <section>
      <div class="example-button-row">
        <button mat-button color="primary" routerLink="/account" (click)="openSnackBarSub()">Inscription</button>
      </div>
    </section>
  </mat-card-content>
</mat-card>
</div>
