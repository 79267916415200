import { Constraints } from './../Models/Constraints';
import { StructureSection } from './../Models/StructureSection';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-financial-section',
  templateUrl: './financial-section.component.html',
  styleUrls: ['./financial-section.component.css']
})
export class FinancialSectionComponent implements OnInit {

  @Input() structureSection: StructureSection;
  @Input() constraint: Constraints;
  constructor() { }

  ngOnInit() {
  }

  get totalCost(): number {
    let sum: number = 0;

    for (let i = 0; i < this.structureSection.falseClues.length; i++) {
      sum += +this.structureSection.falseClues[i].budget;
    }

    const riddles = this.structureSection.riddles;
    for (let i = 0; i < riddles.length; i++) {
      const riddle = riddles[i];

      for (let j = 0; j < riddle.clues.length; j++) {
        sum += +riddle.clues[j].budget;
      }
    }

    return sum;
  }

}
