import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-unsub-snackbar',
  templateUrl: './unsub-snackbar.component.html',
  styleUrls: ['./unsub-snackbar.component.css']
})
export class UnsubSnackbarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
