<mat-card class="subTiles">
  <mat-card-header>
    <mat-card-title>
      Pré-requis de l'énigme
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <mat-form-field>
      <mat-select placeholder="Choisissez un pré-requis">
        <mat-option value="1">
          1
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-select placeholder="Choisissez une option">
        <mat-option value="1">
          1
        </mat-option>
      </mat-select>
    </mat-form-field>
  </mat-card-content>
</mat-card>
