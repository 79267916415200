
<h2 class="globalBloc">Bienvenue voici votre tableau de bord :</h2>

<h2 class="globalBloc"><mat-icon class="titleIcons">description</mat-icon>Mes projets<button class="mat-tooltip"
                                                       matTooltip="Voici la liste de vos projets en cours"
                                                       aria-label="Button that displays a tooltip when focused or hovered over">
  <mat-icon>help_outline</mat-icon>
</button></h2>

<div class="matCardPosition">
  <mat-icon class="before">navigate_before</mat-icon>
<mat-card class="globalBloc">
  <mat-icon>add</mat-icon><br>
  <p><mat-card-title>Créer un projet</mat-card-title></p>
</mat-card><br>

<mat-card class="globalBloc">
  <mat-card-title>Projet 1</mat-card-title>
  <div class="iconsList">
    <button class="matIcons" (click)="openOptionsDialog()"><mat-icon>more_vert</mat-icon></button>
  <button class="matIcons"><mat-icon>visibility</mat-icon></button>
    <a routerLink="/project">
      <button class="matIcons"><mat-icon>edit</mat-icon></button>
    </a>
  <button class="matIcons"><mat-icon>archive</mat-icon></button>
  <button class="matIcons"><mat-icon>file_copy</mat-icon></button>
  <button class="matIcons" (click)="openDeleteDialog()"><mat-icon>
    delete</mat-icon></button>
  </div>
</mat-card><br>

<mat-card class="globalBloc">
  <mat-card-title>Projet 2</mat-card-title>
  <div class="iconsList">
    <button class="matIcons" (click)="openOptionsDialog()"><mat-icon>more_vert</mat-icon></button>
    <button class="matIcons"><mat-icon>visibility</mat-icon></button>
    <a routerLink="/project">
      <button class="matIcons"><mat-icon>edit</mat-icon></button>
    </a>
    <button class="matIcons"><mat-icon>archive</mat-icon></button>
    <button class="matIcons"><mat-icon>file_copy</mat-icon></button>
    <button class="matIcons" (click)="openDeleteDialog()"><mat-icon>
      delete</mat-icon></button>
  </div>
</mat-card><mat-icon class="after">navigate_next</mat-icon><br>
</div>

<h2 class="globalBloc"><mat-icon class="titleIcons">archive</mat-icon>Mes projets archivés <button class="mat-tooltip"
                                                             matTooltip="Projets terminés que vous souhaitez conserver mais ne plus modifier"
                                                             aria-label="Button that displays a tooltip when focused or hovered over">
  <mat-icon>help_outline</mat-icon>
</button></h2>

<div class="matCardPosition">
<mat-icon class="before">navigate_before</mat-icon>
<mat-card class="globalBloc"><br>
  <mat-card-title>Projet 4</mat-card-title>
  <div class="iconsList">
    <button class="matIcons" (click)="openOptionsDialog()"><mat-icon>more_vert</mat-icon></button>
    <button class="matIcons"><mat-icon>visibility</mat-icon></button>
    <a routerLink="/project">
      <button class="matIcons"><mat-icon>edit</mat-icon></button>
    </a>
    <button class="matIcons"><mat-icon>archive</mat-icon></button>
    <button class="matIcons"><mat-icon>file_copy</mat-icon></button>
    <button class="matIcons" (click)="openDeleteDialog()"><mat-icon>
      delete</mat-icon></button>
  </div>
</mat-card><mat-icon class="after">navigate_next</mat-icon><br>
</div>



<h2 class="globalBloc"><mat-icon class="titleIcons">shopping_cart</mat-icon>Mon panier <button class="mat-tooltip"
                                                         matTooltip="Pour sauvegarder des morceaux de projets et les réutiliser dans d'autres projets"
                                                         aria-label="Button that displays a tooltip when focused or hovered over">
  <mat-icon>help_outline</mat-icon>
</button></h2>

<div class="matCardPosition">
  <mat-icon class="before">navigate_before</mat-icon>
<mat-card class="globalBloc">
  <mat-card-title>Enigme 1</mat-card-title>
  <div class="iconsList">
    <button class="matIcons" (click)="openOptionsDialog()"><mat-icon>more_vert</mat-icon></button>
    <button class="matIcons"><mat-icon>visibility</mat-icon></button>
    <a routerLink="/project">
      <button class="matIcons"><mat-icon>edit</mat-icon></button>
    </a>
    <button class="matIcons"><mat-icon>archive</mat-icon></button>
    <button class="matIcons"><mat-icon>file_copy</mat-icon></button>
    <button class="matIcons" (click)="openDeleteDialog()"><mat-icon>
      delete</mat-icon></button>
  </div>
</mat-card><br>

<mat-card class="globalBloc">
  <mat-card-title>Objectif 1</mat-card-title>
  <div class="iconsList">
    <button class="matIcons" (click)="openOptionsDialog()"><mat-icon>more_vert</mat-icon></button>
    <button class="matIcons"><mat-icon>visibility</mat-icon></button>
    <a routerLink="/project">
      <button class="matIcons"><mat-icon>edit</mat-icon></button>
    </a>
    <button class="matIcons"><mat-icon>archive</mat-icon></button>
    <button class="matIcons"><mat-icon>file_copy</mat-icon></button>
    <button class="matIcons" (click)="openDeleteDialog()"><mat-icon>
      delete</mat-icon></button>
  </div>
</mat-card><mat-icon class="after">navigate_next</mat-icon><br>
</div>
