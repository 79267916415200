<mat-card class="fullCard">
  <mat-card-title>Contact</mat-card-title>
  <br>

  <mat-card class="message">
    <mat-card-content>Laissez-nous un message et notre équipe vous répondra dans les plus brefs délais !
    </mat-card-content>
  </mat-card>
  <br>

  <div class="matCardPosition">
      <mat-card-content>
        <p><mat-icon class="iconsContact">account_circle</mat-icon>
          <mat-form-field appearance="outline" class="inputSize">
            <mat-label>Pseudonyme</mat-label>
            <input matInput placeholder="Ex. John Doe">
          </mat-form-field>
        </p>
        <mat-icon class="iconsContact">email</mat-icon>
        <mat-form-field appearance="outline" class="inputSize">
          <mat-label>E-mail</mat-label>
          <input matInput placeholder="jonhdoe@email.com">
        </mat-form-field>
      </mat-card-content><br>

    <form>
      <mat-form-field class="example-full-width">
        <mat-label>Votre message</mat-label>
        <textarea matInput placeholder=""></textarea>
      </mat-form-field>
    </form>
    <br>

    <section>
      <div class="example-button-row">
        <button mat-button color="primary" (click)="openSnackBarSend()">Envoyer
          <mat-icon class="sendButton">send</mat-icon>
        </button>
      </div>
    </section>





