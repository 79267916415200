import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import {SubscribeSnackbarComponent} from '../subscribe-snackbar/subscribe-snackbar.component';
import {LoginSnackbarComponent} from '../login-snackbar/login-snackbar.component';


@Component({
  selector: 'app-login-section',
  templateUrl: './login-section.component.html',
  styleUrls: ['./login-section.component.css']
})
export class LoginSectionComponent implements OnInit {
  durationInSecond = 5;
  hide: any;

  constructor(public authService: AuthService, private _snackBar: MatSnackBar) { }

  openSnackBarSub() {
    this._snackBar.openFromComponent(SubscribeSnackbarComponent, {
      duration: this.durationInSecond * 1000,
    });
  }

  openSnackBarLog() {
    this._snackBar.openFromComponent(LoginSnackbarComponent, {
      duration: this.durationInSecond * 1000,
    });
  }

  ngOnInit(): void {
  }

}
