import { Component, OnInit } from '@angular/core';
import {UnsubscribeModalComponent} from '../Modals/unsubscribe-modal/unsubscribe-modal.component';
import {MatDialog} from '@angular/material/dialog';
import {AuthService} from '../auth.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SaveSnackbarComponent} from '../save-snackbar/save-snackbar.component';

@Component({
  selector: 'app-account-section',
  templateUrl: './account-section.component.html',
  styleUrls: ['./account-section.component.css']
})
export class AccountSectionComponent implements OnInit {
durationInSecond = 5;
  hide: any;

  constructor(private dialog: MatDialog, public authService: AuthService, private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
  }

  openDialog() {
    this.dialog.open(UnsubscribeModalComponent);
  }

  openSnackBarSave() {
    this._snackBar.openFromComponent(SaveSnackbarComponent, {
      duration: this.durationInSecond * 1000,
    });
  }

}
