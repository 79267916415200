import { Component, OnInit } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {DeleteModalComponent} from '../Modals/delete-modal/delete-modal.component';
import {OptionsModalComponent} from '../Modals/options-modal/options-modal.component';

@Component({
  selector: 'app-dashboard-section',
  templateUrl: './dashboard-section.component.html',
  styleUrls: ['./dashboard-section.component.css']
})
export class DashboardSectionComponent implements OnInit {

  constructor(private dialog: MatDialog) { }

  ngOnInit(): void {
  }

  openDeleteDialog() {
    this.dialog.open(DeleteModalComponent);
  }

  openOptionsDialog() {
    this.dialog.open(OptionsModalComponent);
  }
}
