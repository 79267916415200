<mat-grid-list cols="5" rowHeight="3rem">
  <mat-grid-tile>
    <button mat-button (click)="selectLanguage('fr')">
      <img src="assets/img/flags/fr.png" alt="français">
      French
    </button>
  </mat-grid-tile>
  <mat-grid-tile>
    <button mat-button (click)="selectLanguage('en')">
      <img src="assets/img/flags/en.png" alt="anglais">
      English
    </button>
  </mat-grid-tile>
  <mat-grid-tile>
    <button mat-button (click)="selectLanguage('el')">
      <img src="assets/img/flags/el.png" alt="grec">
      Greek
    </button>
  </mat-grid-tile>
  <mat-grid-tile>
    <button mat-button (click)="selectLanguage('es')">
      <img src="assets/img/flags/es.png" alt="espagnol">
      Spanish
    </button>
  </mat-grid-tile>
</mat-grid-list>
