import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, Input } from '@angular/core';
import { Riddle } from '../Models/Riddle';
import { StructureSection } from '../Models/StructureSection';

@Component({
  selector: 'app-segam-section',
  templateUrl: './segam-section.component.html',
  styleUrls: ['./segam-section.component.css']
})
export class SegamSectionComponent {

  @Input() riddleSection: StructureSection;

  constructor(private translate: TranslateService) { }

  addStep() {
    this.riddleSection.stepsNb++;
  }

  addRiddle() {
    const newRiddle = this.riddleSection.createNewRiddle();
    newRiddle.label = this.translate.instant('structure.riddle.label.newLabel');
  }

}
