<mat-card class="subTiles">
  <mat-card-content>

    <mat-form-field>
      <input matInput type="text" placeholder="{{'structure.clue.label' | translate}}" [(ngModel)]="clue.label">
    </mat-form-field>

    <div class="radioButtons">
      <div class="radioButtonsRow">
        <mat-radio-group [(ngModel)]="clue.isMandatory">
          <mat-radio-button value="true"><span translate>structure.riddle.clue.mandatory</span></mat-radio-button>
          <mat-radio-button value="false"><span translate>structure.riddle.clue.optional</span></mat-radio-button>
        </mat-radio-group>
      </div>

      <div class="radioButtonsRow">
        <mat-radio-group [(ngModel)]="clue.isPhysical">
          <mat-radio-button value="true"><span translate>structure.riddle.clue.physical</span></mat-radio-button>
          <mat-radio-button value="false"><span translate>structure.riddle.clue.virtual</span></mat-radio-button>
        </mat-radio-group>
      </div>      
    </div>

    <div class="structureRow">
      <mat-form-field>
        <input matInput type="text" placeholder="{{'structure.riddle.clue.hardware' | translate}}" [(ngModel)]="clue.hardware">
      </mat-form-field>
      <mat-form-field>
        <input matInput type="text" placeholder="{{'structure.riddle.clue.cost' | translate}}" [(ngModel)]="clue.budget">
      </mat-form-field>
    </div>

    <div class="structureRow">
      <mat-form-field>
        <mat-select placeholder="{{'structure.riddle.clue.findAt.placeholder' | translate}}" [(ngModel)]="clue.inLevel">
          <mat-option *ngFor="let step of steps;" value="{{step}}">
            {{step}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <input matInput type="text" placeholder="{{'structure.riddle.clue.location' | translate}}" [(ngModel)]="clue.location">
      </mat-form-field>
    </div>


  </mat-card-content>
</mat-card>
