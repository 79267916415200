<div class="sections">
  <app-home id="title"></app-home>
</div>

<!--
<span id="pdf-form">
  <app-goal-section class="sections" id="goals"></app-goal-section>
<app-constraint-section [constraint]="constraint" class="sections" id="constraints"></app-constraint-section>
  <app-background-section class="sections" id="background"></app-background-section>
  <app-organisation-section class="sections" id="organisation"></app-organisation-section>
  <app-structure-section [riddleSection]="riddle" [goalSkills]="goal.skills" class="sections" id="riddle">
  </app-structure-section>
  <app-financial-section [structureSection]="riddle" [constraint]="constraint" class="sections" id="financial"></app-financial-section>
  <app-segam-section [riddleSection]="riddle" class="sections" id="segam"></app-segam-section>
</span>


<app-segam-preview [riddleSection]="riddle"></app-segam-preview>

-->
