<mat-expansion-panel class="pdf-section">
  <mat-expansion-panel-header>
    <mat-panel-title class="pdf-section-title">
      <span translate>sidenav.background</span>
    </mat-panel-title>
  </mat-expansion-panel-header>

  <mat-form-field class="backgroundInput">
    <textarea matInput [(ngModel)]='background.finalGoal' placeholder="{{'background.finalgoal'| translate}}" class="pdf-mat-input"></textarea>
  </mat-form-field>

  <mat-form-field class="backgroundInput">
    <textarea matInput [(ngModel)]='background.story' placeholder="{{'background.scenario'| translate}}" class="pdf-mat-input"></textarea>
  </mat-form-field>

  <mat-form-field class="backgroundInput">
    <textarea matInput [(ngModel)]='background.failureConsequences' placeholder="{{'background.failure.consequence'| translate}}" class="pdf-mat-input"></textarea>
  </mat-form-field>

  <mat-card class="subTiles pdf-table">
    <mat-card-header>
      <mat-card-title class="pdf-table-title">
        <span translate>background.character.title</span>
      </mat-card-title>
    </mat-card-header>

    <mat-card-content>
      <div *ngFor="let charac of background.character; let i = index" class="pdf-table-row"
      style="flex-direction: row; box-sizing: border-box; display: flex; place-content: center space-around; align-items: center;">
        <div class="object" 
        style="flex-direction: column; box-sizing: border-box; display: flex; place-content: stretch flex-start; align-items: baseline;">
          <mat-form-field class="backgroundInput">
            <input matInput type="text" placeholder="{{'background.character.name'| translate}}" [(ngModel)]="charac.name" class="pdf-mat-input pdf-table-item">
          </mat-form-field>
          <mat-form-field class="backgroundInput">
            <input matInput type="text" placeholder="{{'background.character.caracteristic'| translate}}" [(ngModel)]="charac.caracteristics" class="pdf-mat-input pdf-table-item">
          </mat-form-field>
        </div>
        <button class="buttonSup" (click)="removeCharacter(i)">-</button>
      </div>
    </mat-card-content>
    <button mat-raised-button color="primary" type="button" (click)="addCharacter()">
     <span translate>background.buttons.addCharacter</span>
    </button>
  </mat-card>
  <br>

  
  <mat-card class="subTiles pdf-table">
    <mat-card-header>
      <mat-card-title class="pdf-table-title" translate>
        <span translate>background.objects</span>
      </mat-card-title>
    </mat-card-header>

    <mat-card-content>
      <div *ngFor="let deco of background.decorations; let i = index" class="pdf-table-row"
      style="flex-direction: row; box-sizing: border-box; display: flex; place-content: center space-around; align-items: center;">
        <div class="object" 
        style="flex-direction: column; box-sizing: border-box; display: flex; place-content: stretch flex-start; align-items: baseline;">
          <mat-form-field class="backgroundInput">
            <input matInput type="text" placeholder="{{'background.object.label'| translate}}" [(ngModel)]="deco.label" class="pdf-mat-input pdf-table-item">
          </mat-form-field>
          <mat-form-field class="backgroundInput">
            <input matInput type="text" [(ngModel)]="deco.description" placeholder="{{'background.object.description'| translate}}" class="pdf-mat-input pdf-table-item">
          </mat-form-field>
        </div>
        <button class="buttonSup" (click)="removeDecoration(i)">-</button>
      </div>
    </mat-card-content>

    <button mat-raised-button color="primary" type="button" (click)="addDecoration()">
      <span translate>background.buttons.addObject</span>
    </button>
  </mat-card>
  <br>


  <!--
  <mat-radio-group [(ngModel)]='background.socialModel' class="pdf-mat-radio">
    <label translate>background.social.label</label>
    <div>
      <mat-radio-button value="Collaborative"><span translate>background.social.collaborative</span></mat-radio-button>
    </div>
    <div>
      <mat-radio-button value="Competitive"><span translate>background.social.competitive</span></mat-radio-button>
    </div>
  </mat-radio-group>
  -->

  <mat-selection-list [(ngModel)]='background.socialModel' class="pdf-mat-radio">
    <label translate>background.social.label</label>
    <mat-list-option value="Collaborative" checkboxPosition="before"><span translate>background.social.collaborative</span></mat-list-option>
    <mat-list-option value="Competitive" checkboxPosition="before"><span translate>background.social.competitive</span></mat-list-option>
  </mat-selection-list>

</mat-expansion-panel>
