import { RiddlePreRequisite } from './RiddlePreRequisite';
import { Clue } from './Clue';
import { Skill } from './Skill';
import { GoalPreRequisite } from './GoalPreRequisite'
import { RiddleSkill } from './RiddleSkill';

export class Riddle {
  label: string;
  answersTo: RiddleSkill[] = [];
  preRequisite: RiddlePreRequisite[] = [];
  riddleReward: string;
  help: string;
  inLevel: string;
  color: string;
  clues: Clue[] = [];
  answer: string;
  isSameForAll: boolean;
  endsAt: number;
}
