<mat-card>
  <mat-card-title>Mon compte</mat-card-title><br>
  <mat-card-content>
    <p>Pseudo : </p>
      <mat-form-field appearance="outline">
        <mat-label>Pseudonyme</mat-label>
        <input matInput placeholder="Ex. John Doe" value="John Doe">
      </mat-form-field>


    <p>E-mail :</p>
      <mat-form-field appearance="outline">
        <mat-label>E-mail</mat-label>
        <input matInput placeholder="8 car. min" value="johndoe@mail.fr">
      </mat-form-field>


    <p>Mot de passe actuel :</p>
      <mat-form-field appearance="outline">
        <mat-label>Mot de passe</mat-label>
        <input matInput placeholder="" value="mdpperso1234"  [type]="hide ? 'password' : 'text'" formControlName="password" required>
        <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
      </mat-form-field>


    <p>Nouveau mot de passe :</p>
      <mat-form-field appearance="outline">
        <mat-label>Nouveau mot de passe</mat-label>
        <input matInput placeholder="" [type]="hide ? 'password' : 'text'" formControlName="password" required>
        <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
      </mat-form-field>

    <p>Confirmation du mot de passe :</p>
      <mat-form-field appearance="outline">
        <mat-label>Confirmation nouveau mot de passe</mat-label>
        <input matInput placeholder="Ex. John Doe" [type]="hide ? 'password' : 'text'" formControlName="password" required>
        <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
      </mat-form-field>

    <section>
      <div class="example-button-row">
        <button mat-button color="primary" (click)="openSnackBarSave()">
          <mat-icon class="save">save</mat-icon>
          Enregistrer les modifications</button>
      </div>
    </section><br>

    <button mat-button (click)="openDialog()">Se désinscrire</button>
  </mat-card-content>
</mat-card>
