import {Component, ViewChild} from '@angular/core';
import { Project } from '../Models/Project';
import * as JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { ProjectService } from '../project-service/project.service';
import { MatDialog } from '@angular/material/dialog';
import { SaveDialogComponent } from '../save-dialog/save-dialog.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-general-form',
  templateUrl: './general-form.component.html',
  styleUrls: ['./general-form.component.css']
})

export class GeneralFormComponent {

  project: Project;
  @ViewChild('fileInput') fileInput: any;

  constructor(private projectService: ProjectService, private dialog: MatDialog, private translate: TranslateService) {
    this.project = projectService.project;
  }

  downloadProject() {
    const dialogRef = this.dialog.open(SaveDialogComponent, {
      data: {
        projectName: this.project.projectName,
        result: false
      }
    });
    dialogRef.afterClosed().subscribe(data => {
      if (data && data.result) {

        this.project.projectName = data.projectName;
        const jsonString = JSON.stringify(this.project, undefined, 2);
        const zip = new JSZip();
        zip.file('project.json', jsonString);
  
        zip.generateAsync({ type: 'blob' }).then((content: Blob) => {
          saveAs(content, (this.project.projectName ? this.project.projectName : this.translate.instant('save.dialog.namePlaceholder')) +' - CreationSEG' + '.zip');
        });
      }
    });
  }

  selectFile(): void {
    this.fileInput.nativeElement.click();
  }
  
  onFileSelected(event: any): void {
    const selectedFile: File = event.target.files[0];
    if (selectedFile) {
      const reader: FileReader = new FileReader();
      
      reader.onload = (e) => {
        const arrayBuffer = (e.target as FileReader).result;
        if (arrayBuffer) {
          const jszip = new JSZip();
          jszip.loadAsync(arrayBuffer).then((zip) => {

            
            if (zip.files['project.json'] !== undefined) {
              zip.forEach((relativePath, file) => {
  
                if (relativePath == 'project.json') {
                  file.async('string').then((content) => {
                    const jsonData: any = JSON.parse(content);
                    const project: Project = Object.assign(new Project(), jsonData);
                    this.project = project;
                    this.projectService.project = project;
                  })
                }
              });
              
            }

          });
        }
        
      }

      reader.readAsArrayBuffer(selectedFile);
    }
  }
}
