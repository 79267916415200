<div class="sections">
  <app-home id="title"></app-home>
</div>

<!--
<div>
  <mat-card class="warning">
    <mat-card-content>En remplissant ce projet sans être connecté, vous ne pourrez faire qu'un export téléchargeable.
      Aucune donnée ne sera sauvegardée pour un usage ultérieur.<br>
      Pour vous connecter ou vous inscrire, cliquez sur le lien en haut à gauche de la page.</mat-card-content>
  </mat-card>
</div>
-->

<span id="pdf-form">
  <input type="file" #fileInput style="display: none" (change)="onFileSelected($event)" accept=".zip">
  <button mat-raised-button color="primary" type="button" id="importButton" (click)="selectFile()"><span translate>save.importProject</span></button>
  <app-goal-section [goal]="project.goal" class="sections" id="goals"></app-goal-section>
  <app-constraint-section [constraint]="project.constraints" class="sections" id="constraints"></app-constraint-section>
  <app-background-section [background]="project.background" class="sections" id="background"></app-background-section>
  <app-organisation-section [organisation]="project.organisation" class="sections" id="organisation"></app-organisation-section>
  <app-structure-section [riddleSection]="project.structure" [goalSkills]="project.goal.skills" class="sections" id="riddle"></app-structure-section>
  <app-financial-section [structureSection]="project.structure" [constraint]="project.constraints" class="sections" id="financial"></app-financial-section>
  <app-segam-section [riddleSection]="project.structure" class="sections" id="segam"></app-segam-section>
  <button mat-raised-button color="primary" type="button" id="importButton" (click)="downloadProject()"><span translate>save.saveProject</span></button>
</span>


<app-segam-preview [riddleSection]="project.structure"></app-segam-preview>