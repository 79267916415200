<mat-expansion-panel class="pdf-section">
  <mat-expansion-panel-header>
    <mat-panel-title class="pdf-section-title">
      <span translate>sidenav.constraints</span>
    </mat-panel-title>
  </mat-expansion-panel-header>


      <mat-form-field class="contrainteInput">
        <input matInput type="text" [(ngModel)]='constraint.activityDuration' placeholder="{{'constraint.duration' | translate}}"
               class="pdf-mat-input">

      </mat-form-field>

    <!-- Supprimé de la maquette ?
    <mat-grid-tile>
      <mat-form-field class="contrainteInput">
        <input matInput type="text" [(ngModel)]='constraint.nbOfSessions' placeholder="Nombre de séances disponibles"
               class="pdf-mat-input">
      </mat-form-field>
    </mat-grid-tile>-->
    <div style="flex-direction: row;">
      <mat-form-field class="contraintNumberInput">
        <input matInput type="text" [(ngModel)]='constraint.nbOfStudents' placeholder="{{'constraint.studentPerSession' | translate}}"
               class="pdf-mat-input">
      </mat-form-field>

      <mat-form-field class="contraintNumberInput">
        <input matInput type="text" [(ngModel)]='constraint.nbPerGroup' placeholder="{{'constraint.studentPerTeam' | translate}}" class="pdf-mat-input">
      </mat-form-field>

      <mat-form-field class="contraintNumberInput">
        <input matInput type="text" [(ngModel)]='constraint.simultaneousGroup'
               placeholder="{{'constraint.teamsPerSession' | translate}}" class="pdf-mat-input">
      </mat-form-field>
    </div>

  <mat-card class="subTiles pdf-table">
    <mat-card-header>
      <mat-card-title class="pdf-table-title">
        <span translate>constraint.location.title</span>
      </mat-card-title>
    </mat-card-header>

    <mat-card-content class="pdf-table-row">
      <mat-selection-list [(ngModel)]='constraint.location.label' class="pdf-mat-radio pdf-table-item">
        <mat-list-option value="Inside" checkboxPosition="before"><span translate>constraint.location.inside.label</span></mat-list-option>
        <mat-list-option value="Outside" checkboxPosition="before"><span translate>constraint.location.outside</span></mat-list-option>
        <mat-list-option value="Virtual" checkboxPosition="before"><span translate>constraint.location.virtual</span></mat-list-option>

       <!-- No file for now
       <div *ngIf="constraint.location == 'Interieur'">
          <input type="file" (change)="onFileSelected($event)">
        </div>-->
      </mat-selection-list>
      <mat-form-field class="contrainteInput">
        <input matInput type="text" [(ngModel)]='constraint.location.availableRoom'
               placeholder="{{'constraint.location.inside.rooms' | translate}}"
               class="pdf-table-input pdf-table-item">
      </mat-form-field>
    </mat-card-content>
  </mat-card>

  <mat-form-field>
    <input matInput type="text" [(ngModel)]='constraint.budget' placeholder="{{'constraint.budget' | translate}}" class="pdf-mat-input">
  </mat-form-field>

  <mat-form-field>
    <input matInput type="text" [(ngModel)]='constraint.teachers' placeholder="{{'constraint.teachers' | translate}}" class="pdf-mat-input">
  </mat-form-field>

  <br>


  <mat-radio-group [(ngModel)]='constraint.wifi' class="pdf-mat-radio">
    <label translate>constraint.network</label>
    <div>
      <mat-radio-button value=true><span translate>general.yes</span></mat-radio-button>
    </div>
    <div>
      <mat-radio-button value=false><span translate>general.no</span></mat-radio-button>
    </div>
  </mat-radio-group>

  <mat-radio-group [(ngModel)]='constraint.data' class="pdf-mat-radio">
    <label translate>constraint.mobile</label>
    <div>
      <mat-radio-button value=true><span translate>general.yes</span></mat-radio-button>
    </div>
    <div>
      <mat-radio-button value=false><span translate>general.no</span></mat-radio-button>
    </div>
  </mat-radio-group>

  <mat-form-field>
    <input matInput type="text" [(ngModel)]='constraint.otherConstraints' placeholder="{{'constraint.additionnal' | translate}}" class="pdf-mat-input">
  </mat-form-field>
</mat-expansion-panel>
