import { Component, OnInit } from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {UnsubSnackbarComponent} from '../../unsub-snackbar/unsub-snackbar.component';

@Component({
  selector: 'app-unsubscribe-modal',
  templateUrl: './unsubscribe-modal.component.html',
  styleUrls: ['./unsubscribe-modal.component.css']
})
export class UnsubscribeModalComponent implements OnInit {
durationInSecond = 5;

  constructor(private _snackBar: MatSnackBar) { }

  openSnackBarUnsub() {
    this._snackBar.openFromComponent(UnsubSnackbarComponent, {
      duration: this.durationInSecond * 1000,
    });
  }
  ngOnInit(): void {
  }

}
