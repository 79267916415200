<mat-card class="pdf-section">
  <mat-card-header>
    <mat-card-title class="pdf-section-title">
      <span translate>sidenav.segam</span>
    </mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <app-segam [riddleSection]="riddleSection" class="pdf-image"></app-segam>
    <button mat-raised-button color="primary" type="button" (click)="addRiddle()">
      <span translate>structure.buttons.addRiddle</span>
    </button>
    <button mat-raised-button color="primary" type="button" (click)="addStep()">
      <span translate>structure.buttons.addStep</span>
    </button>

  </mat-card-content>
</mat-card>
