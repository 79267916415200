<h2 class="globalBloc">A propos : Les concepteurs de cet outil</h2>

<div class="matCardPosition">
<mat-card>
  <mat-card-header>
  <mat-card-title>Gaëlle Guigon</mat-card-title><br>
  <mat-card-subtitle>Fondatrice de l'outils</mat-card-subtitle>
    <mat-card-subtitle>Ingénieure Techno-Pédagogique à IMT Nord Europe</mat-card-subtitle>
  </mat-card-header>
  <a href="https://www.linkedin.com/in/ga%C3%ABlle-guigon-21a33360/"><img mat-card-image src="assets/img/GaelleGuigon.JPG" alt="Gaëlle Guigon"></a>
</mat-card>

<mat-card>
  <mat-card-header>
    <mat-card-title>Amélia Bouchard</mat-card-title><br>
    <mat-card-subtitle>Co-conceptrice de l'outils</mat-card-subtitle>
    <mat-card-subtitle>Apprentie Web-Conceptrice à IMT Nord Europe</mat-card-subtitle>
  </mat-card-header>
  <a href="https://www.linkedin.com/in/ameliabouchard/"><img mat-card-image src="assets/img/AmeliaBouchard.JPG"></a>
</mat-card>

<mat-card>
  <mat-card-header>
    <mat-card-title>Patrick Delaporte</mat-card-title><br>
    <mat-card-subtitle>Co-créateur de l'outils</mat-card-subtitle>
    <mat-card-subtitle>Responsable Ingénieurie Logicielle chez Décathlon</mat-card-subtitle>
  </mat-card-header>
  <a href="https://www.linkedin.com/in/patrick-delaporte-b6b60260/"><img mat-card-image src="assets/img/PatrickDelaporte.JPG"></a>
</mat-card>
</div><br>

<div class="globalBloc">
<!--
<mat-accordion class="globalBloc">
<mat-expansion-panel (opened)="panelOpenState = true"
                     (closed)="panelOpenState = false">
  <mat-expansion-panel-header>
    <mat-panel-title>
      Améliorations prévues
    </mat-panel-title>
  </mat-expansion-panel-header>
</mat-expansion-panel>
</mat-accordion><br>

<mat-accordion>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        Mise à jour du xx/xx/xx
      </mat-panel-title>
    </mat-expansion-panel-header>
    <p></p>
  </mat-expansion-panel>
</mat-accordion><br>

<mat-accordion>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        Mise à jour du xx/xx/xx
      </mat-panel-title>
    </mat-expansion-panel-header>
    <p></p>
  </mat-expansion-panel>
</mat-accordion><br>
-->
</div>
