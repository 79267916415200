<mat-card class="subTiles">
  <mat-card-header>
    <mat-card-title>
      <span translate>goals.newSkill.title</span>
    </mat-card-title>
    <button class="buttonClose" mat-raised-button (click)="removeSkill()" color="primary">
    </button>
  </mat-card-header>
  <mat-card-content>
    <mat-form-field>
      <input matInput type="text" [(ngModel)]="skill.label" placeholder="{{'goals.skill.label' | translate}}">
    </mat-form-field>
    <br>
    <mat-radio-group [(ngModel)]="skill.priority">
      <mat-radio-button value="Primary"><span translate>goals.skill.primary</span></mat-radio-button>
      <mat-radio-button value="Secondary"><span translate>goals.skill.secondary</span></mat-radio-button>
    </mat-radio-group>
    <mat-accordion>
      <mat-card-content>
        <mat-radio-group *ngFor="let prerequisite of skill.prerequisite; let i = index;">
          <mat-form-field class="skillInput">
            <input matInput type="text" [(ngModel)]="prerequisite.label" placeholder="{{'goals.skill.prerequisite' | translate}} #{{i+1}}">
          </mat-form-field>
          <button class="buttonSup" (click)="removePreRequisite(i)">-</button>

        </mat-radio-group>

      </mat-card-content>
      <button mat-raised-button color="primary" type="button" (click)="addPreRequisite()">
        <span translate>goals.buttons.addPreRequisite</span>
      </button>
    </mat-accordion>
  </mat-card-content>
</mat-card>
