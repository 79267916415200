import { Riddle } from './Riddle';
import { Clue } from './Clue';

export class StructureSection {
  stepsNb: number;
  riddles: Riddle[];
  falseClues: Clue[];

  constructor() {
    this.riddles = [];
    this.falseClues = [];
    this.stepsNb = 0;
  }

  createNewRiddle(): Riddle {
    const riddle = new Riddle();
    riddle.label = 'Nouvelle énigme';
    riddle.color = this.getRandomColor();

    this.riddles.push(riddle);

    return riddle;
  }

  private getRandomColor(): string {
    var color = Math.floor(0x1000000 * Math.random()).toString(16);
    return '#' + ('000000' + color).slice(-6);
  }
}
