import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {NavbarComponent} from './navbar/navbar.component';
import {HomeComponent} from './home/home.component';
import {GoalSectionComponent} from './goal-section/goal-section.component';
import {ConstraintSectionComponent} from './constraint-section/constraint-section.component';
import {BackgroundSectionComponent} from './background-section/background-section.component';
import {OrganisationSectionComponent} from './organisation-section/organisation-section.component';
import {StructureSectionComponent} from './structure-section/structure-section.component';
import {FinancialSectionComponent} from './financial-section/financial-section.component';
import {SegamSectionComponent} from './segam-section/segam-section.component';
import {HelpSectionComponent} from './help-section/help-section.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MaterialModule} from './material/material.module';
import {MainLayoutComponent} from './main-layout/main-layout.component';
import {ScrollToModule} from '@nicky-lenaers/ngx-scroll-to';
import {PreRequisiteCardComponent} from './structure-section/duplicable-cards/pre-requisite-card/pre-requisite-card.component';
import {ClueCardComponent} from './structure-section/duplicable-cards/clue-card/clue-card.component';
import {GeneralFormComponent} from './general-form/general-form.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import {GoalSubsectionComponent} from './goal-section/duplicable-cards/goal-subsection/goal-subsection.component';
import {SegamComponent} from './segam/segam.component';
import {SegamPreviewComponent} from './segam-preview/segam-preview.component';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {LanguagePickerModalComponent} from './language-picker-modal/language-picker-modal.component';
import {StructureSubsectionComponent} from './structure-section/duplicable-cards/riddle-subsection/structure-subsection.component';
import {ContactSectionComponent} from './contact-section/contact-section.component';
import {MatCard, MatCardModule} from '@angular/material/card';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import { LoginSectionComponent } from './login-section/login-section.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import { AccountSectionComponent } from './account-section/account-section.component';
import {MatDialog, MatDialogModule} from '@angular/material/dialog';
import { UnsubscribeModalComponent } from './Modals/unsubscribe-modal/unsubscribe-modal.component';
import { AboutSectionComponent } from './about-section/about-section.component';
import { DashboardSectionComponent } from './dashboard-section/dashboard-section.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import { DeleteModalComponent } from './Modals/delete-modal/delete-modal.component';
import { OptionsModalComponent } from './Modals/options-modal/options-modal.component';
import { ProjectSectionComponent } from './project-section/project-section.component';
import { LegalSectionComponent } from './legal-section/legal-section.component';
import {MatMenuModule} from '@angular/material/menu';
import { DisconnectModalComponent } from './Modals/disconnect-modal/disconnect-modal.component';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { SubscribeSnackbarComponent } from './subscribe-snackbar/subscribe-snackbar.component';
import { LoginSnackbarComponent } from './login-snackbar/login-snackbar.component';
import { SendSnackbarComponent } from './send-snackbar/send-snackbar.component';
import { SaveSnackbarComponent } from './save-snackbar/save-snackbar.component';
import { UnsubSnackbarComponent } from './unsub-snackbar/unsub-snackbar.component';
import { DiscoSnackbarComponent } from './disco-snackbar/disco-snackbar.component';
import { SaveDialogComponent } from './save-dialog/save-dialog.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    HomeComponent,
    GoalSectionComponent,
    ConstraintSectionComponent,
    BackgroundSectionComponent,
    OrganisationSectionComponent,
    StructureSectionComponent,
    FinancialSectionComponent,
    SegamSectionComponent,
    HelpSectionComponent,
    MainLayoutComponent,
    PreRequisiteCardComponent,
    ClueCardComponent,
    GeneralFormComponent,
    StructureSubsectionComponent,
    GoalSubsectionComponent,
    SegamComponent,
    SegamPreviewComponent,
    LanguagePickerModalComponent,
    ContactSectionComponent,
    LoginSectionComponent,
    AccountSectionComponent,
    UnsubscribeModalComponent,
    AboutSectionComponent,
    DashboardSectionComponent,
    DeleteModalComponent,
    OptionsModalComponent,
    ProjectSectionComponent,
    LegalSectionComponent,
    DisconnectModalComponent,
    SubscribeSnackbarComponent,
    LoginSnackbarComponent,
    SendSnackbarComponent,
    SaveSnackbarComponent,
    UnsubSnackbarComponent,
    DiscoSnackbarComponent,
    SaveDialogComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    MatButtonModule,
    MatExpansionModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatCardModule,
    MatInputModule,
    MatIconModule,
    MatFormFieldModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatMenuModule,
    MatDialogModule,
    ScrollToModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (HttpLoaderFactory),
        deps: [HttpClient]
      },
    }),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
