<div class="wrapper">
    <div class="row">
      <div class="column"></div>
      <div class="column header" *ngFor="let step of steps">
        <span translate>segam.step</span> {{ step }}
      </div>
    </div>
    <div class="row" *ngFor="let riddle of riddleSection.riddles; let i = index;">
      <div class="column header">
        <!--<span translate>segam.riddle</span> {{ i + 1 }}-->
        {{riddleNameIsSet(riddle,i) ? riddle.label : riddle.label+' '+(i + 1)}}
      </div>
      <div class="column" *ngFor="let step of steps;">
        <div>
          <ng-container *ngFor="let clue of riddle.clues; let i = index;">
            <span class="clue" [style.background-color]="riddle.color" *ngIf="clue.inLevel == step">{{i+1}}</span>
          </ng-container>
        </div>
      </div>
    </div>
</div>
