import {NgModule} from '@angular/core';
import {Routes, RouterModule, ExtraOptions} from '@angular/router';
import {MainLayoutComponent} from './main-layout/main-layout.component';
import {ContactSectionComponent} from './contact-section/contact-section.component';
import {LoginSectionComponent} from './login-section/login-section.component';
import {AccountSectionComponent} from './account-section/account-section.component';
import {AboutSectionComponent} from './about-section/about-section.component';
import {HelpSectionComponent} from './help-section/help-section.component';
import {DashboardSectionComponent} from './dashboard-section/dashboard-section.component';
import {ProjectSectionComponent} from './project-section/project-section.component';
import {GeneralFormComponent} from './general-form/general-form.component';
import {LegalSectionComponent} from './legal-section/legal-section.component';

const routes: Routes = [
  {path: '', component: MainLayoutComponent, children: [
      {path: '', component: GeneralFormComponent, pathMatch: 'full'},
      {path: 'contact', component: ContactSectionComponent, pathMatch: 'full'},
      //{path: 'login', component: LoginSectionComponent, pathMatch: 'full'},
      //{path: 'account', component: AccountSectionComponent, pathMatch: 'full'},
      {path: 'about', component: AboutSectionComponent, pathMatch: 'full'},
      {path: 'help', component: HelpSectionComponent, pathMatch: 'full'},
      //{path: 'dashboard', component: DashboardSectionComponent, pathMatch: 'full'},
      //{path: 'project', component: ProjectSectionComponent, pathMatch: 'full'},
      {path: 'legal', component: LegalSectionComponent, pathMatch: 'full'},
    ]},
  {path: '**', redirectTo: ''},
];

const routerOptions: ExtraOptions = {
  useHash: false,
  anchorScrolling: 'enabled',
  scrollPositionRestoration: 'enabled'
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
