import { Component, OnInit, Input } from '@angular/core';
import { StructureSection } from '../Models/StructureSection';
import { TranslateService } from '@ngx-translate/core';
import { Riddle } from '../Models/Riddle';

@Component({
  selector: 'app-segam',
  templateUrl: './segam.component.html',
  styleUrls: ['./segam.component.css']
})
export class SegamComponent implements OnInit {

  @Input() riddleSection: StructureSection;

  constructor(private translate: TranslateService) { }

  ngOnInit() {
  }

  get steps(): number[] {
    let steps = [];
    let count = this.riddleSection ? this.riddleSection.stepsNb : 0;

    for(let i = 1; i <= count; i++) {
      steps.push(i);
    }

    return steps;
  }

  riddleNameIsSet(riddle: Riddle, riddleIndex: number): boolean {
    let res: boolean = false;
    if (riddle.label+' '+(riddleIndex+1) != 'Nouvelle énigme'+' '+(riddleIndex+1)) {
      res = true;
    }
    return res;
  }

}
